<template>
  <div v-loading="saveBtnLoading" element-loading-text="保存中">
    <div class="titleText">选择监考组</div>
    <el-row class="middleBoxSpacing">
      <!-- 全部考场 -->
      <el-card :body-style="{ padding: '0px' }">
        <div class="treeHead clearfix" slot="header">
          待选人员
          <span>共{{ this.tableDataLeft.length }}人</span>
        </div>
        <!-- 筛选条件 -->
        <el-form :inline="true" :model="param" class="demo-form-inline">
          <el-form-item label="姓名">
            <el-input
              size="mini"
              v-model="param.queryName"
              @change="candidates"
            ></el-input>
          </el-form-item>
          <el-form-item label="科目">
            <el-select
              size="mini"
              v-model="param.querySubjectType"
              @change="candidates"
            >
              <el-option
                v-for="item in filterList.subjectList"
                :key="item"
                :label="item | subjectType"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="区县">
            <el-select
              size="mini"
              v-model="param.querySysOrgAreaId"
              @change="candidatesQuerySysOrgArea"
            >
              <el-option label="全部" :value="0"></el-option>
              <el-option
                v-for="item in filterList.sysOrgAreaDTOList"
                :key="item.sysOrgAreaId"
                :label="item.name"
                :value="item.sysOrgAreaId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学校">
            <el-select
              size="mini"
              v-model="param.querySysOrgSchoolId"
              @change="candidates"
            >
              <el-option label="全部" :value="0"></el-option>
              <el-option
                v-for="item in filterList.sysOrgSchoolDTOList"
                :key="item.sysOrgSchoolId"
                :label="item.name"
                :value="item.sysOrgSchoolId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <!-- 表格 -->
        <!--  -->
        <!-- :row-class-name="tableRowClassName" -->
        <el-table
          v-loading="tableDataLeftLoading"
          element-loading-text="加载中"
          stripe
          height="470"
          ref="tableDataLeft"
          :data="tableDataLeft"
          tooltip-effect="dark"
          style="width: 100%"
          @row-click="rowClickLeft"
          :row-style="rowStyleLeft"
          @selection-change="handleSelectionLeft"
          :header-cell-style="{ background: '#DFE6EC' }"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column prop="nickName" label="姓名"></el-table-column>
          <el-table-column label="科目">
            <template slot-scope="scope">{{
              scope.row.subjectType | subjectType
            }}</template>
          </el-table-column>
          <el-table-column
            prop="sysOrgAreaName"
            label="所属区县"
          ></el-table-column>
          <el-table-column
            prop="sysOrgSchoolName"
            label="所属学校"
          ></el-table-column>
        </el-table>
      </el-card>
      <!-- 迁移按钮 -->
      <div class="capabilitiesBtn">
        <el-button
          v-throttle="true"
          type="primary"
          @click="deleteRight"
          :disabled="!this.temporaryListRight.length"
        >
          <div style="display: flex; align-items: center; line-height: 20px">
            <i class="el-icon-arrow-left" style="font-size: 20px"></i>
            移除
          </div>
        </el-button>
        <el-button
          v-throttle="true"
          type="primary"
          @click="addRight"
          :disabled="!this.temporaryListLeft.length"
        >
          <div style="display: flex; align-items: center; line-height: 20px">
            添加
            <i
              class="el-icon-arrow-right el-icon--right"
              style="font-size: 20px"
            ></i>
          </div>
        </el-button>
      </div>
      <!-- 右边 -->
      <el-card :body-style="{ padding: '0px' }">
        <div class="treeHead clearfix" slot="header">
          已选人员
          <span>共{{ this.tableDataRight.length }}人</span>
        </div>
        <!-- 筛选条件 -->
        <el-form :inline="true" style="display: block">
          <!-- <el-form-item label="姓名">
            <el-input size="mini" v-model="formInline.user"></el-input>
          </el-form-item>-->
          <el-form-item style="margin-left: 5px">
            <el-button
              v-throttle
              type="primary"
              size="mini"
              @click="setTeacherTypeBtn(2)"
              >设为组员</el-button
            >
          </el-form-item>
          <el-form-item>
            <el-button
              v-throttle
              type="primary"
              size="mini"
              @click="setTeacherTypeBtn(1)"
              >设为组长</el-button
            >
          </el-form-item>
        </el-form>
        <!--已选人员表格  -->
        <!-- @row-click="rowClickRight" -->
        <el-table
          stripe
          height="470"
          ref="tableDataRight"
          :data="tableDataRight"
          tooltip-effect="dark"
          style="width: 100%"
          :row-style="rowStyleRight"
          @selection-change="handleSelectionRight($event)"
          :header-cell-style="{ background: '#DFE6EC' }"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column prop="nickName" label="姓名"></el-table-column>
          <el-table-column prop="subjectType" label="科目">
            <template slot-scope="scope">{{
              scope.row.subjectType | subjectType
            }}</template>
          </el-table-column>
          <el-table-column
            prop="sysOrgAreaName"
            label="所属区县"
          ></el-table-column>
          <el-table-column
            prop="sysOrgSchoolName"
            label="所属学校"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column label="身份">
            <template slot-scope="scope">
              <!-- :key="scope.row.userId" -->
              <span
                :key="keyF5"
                :style="scope.row.teacherType == 1 ? 'color: #f59a23' : ''"
              >
                {{ scope.row.teacherType | teacherTypeFilter }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <!-- @click="singleChangeBtn(scope.$index,2)" -->
              <el-button
                v-throttle
                v-if="scope.row.teacherType == 1"
                @click="singleChangeBtn(scope.$index, 2)"
                type="text"
                >设为组员</el-button
              >
              <el-button
                v-throttle
                v-if="scope.row.teacherType == 2"
                @click="singleChangeBtn(scope.$index, 1)"
                type="text"
                >设为组长</el-button
              >
              <!-- {{scope.row.teacherType}} -->
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </el-row>
    <el-row class="middleBox">
      <el-button
        v-throttle
        type="primary"
        size="medium"
        @click="saveBtn"
        :loading="saveBtnLoading"
        >保存</el-button
      >
      <router-link to="/exam/examManage" replace style="margin-left: 30px">
        <el-button v-throttle size="medium">返回</el-button>
      </router-link>
    </el-row>
  </div>
</template>

<script>
import {
  rootOr,
  rootOr2,
  inquireBase,
  teacherList,
  addTeacher,
  InvigilationTeacher,
  renewTeacher,
} from '@/api/exam/exam.js'
import { makeupInvigilation } from '@/api/exam/exam.js'
export default {
  name: 'monitoringArrangements',
  props: ['examId', 'firstTime'],
  data() {
    return {
      saveBtnLoading: false,
      tableDataLeftLoading: false,
      formInline: {
        user: '',
        region: '',
      },
      // 用户角色查询参数
      param: {
        // 过滤的用户id
        excludeUserIdList: [],
        // 	查询用户姓名
        queryName: '',
        // 查询用户角色编码
        queryRoleCode: '',
        // 	查询学科
        querySubjectType: '',
        // 	查询用户角色id
        querySysAuthRoleId: 0,
        // 	查询区县地区
        querySysOrgAreaId: 0,
        // 查询用户学校
        querySysOrgSchoolId: 0,
        // 查询用户编号
        queryUserCode: '',
      },
      filterList: {
        subjectList: null,
        teacherTypeList: [],
        sysOrgAreaDTOList: [],
        sysOrgSchoolDTOList: [],
      },
      tableDataLeft: [],
      tableDataRight: [],
      // 左边临时选中清单
      temporaryListLeft: [],
      // 右边选中清单
      temporaryListRight: [],
      // 临时arr  选中的右边arr
      checkedArrRight: [],
      //请求时参数
      submitData: [],
      // 用来刷新
      keyF5: 1,
    }
  },
  watch: {},
  methods: {
    // 获取所有老师
    async candidates() {
      this.tableDataLeftLoading = true
      await teacherList(this.param).then((res) => {
        console.log('获取所有老师', res)
        if (res.success) {
          this.tableDataLeft = []
          this.tableDataLeft = res.data
          // this.tableDataLeft.forEach((res) => {
          //   res.showLeft = 1
          // })
          this.tableDataLeftLoading = false
        } else {
          this.tableDataLeftLoading = false
        }
      })
    },
    // 获取选中学科
    subjectListFunc() {
      inquireBase(this.examId).then((res) => {
        if (res.success) {
          this.filterList.subjectList = []
          res.data.examArrangementSubjectAndTitleDTOList.forEach((val) => {
            this.filterList.subjectList.push(val.subject)
          })
          this.param.querySubjectType = this.filterList.subjectList[0]
          this.candidates()
        }
      })
    },
    // 获取学校，区县
    getSchoolName() {
      let testInformation = {
        getExamPlace: 0,
        getSchool: 1,
      }
      // if (this.formData.studentNumber.length != 0) return
      rootOr(testInformation).then((res) => {
        console.log('获取学校，区县', res)
        if (res.success) {
          // 去掉第一层的市
          res.data.orgType = undefined
          this.recursionListCounty(res.data)
          this.recursionListSchool(res.data)
        }
      })
    },
    // 递归获取区县名称及ID
    recursionListCounty(arr) {
      if (arr.orgType == 1) {
        let li = { name: arr.orgName, sysOrgAreaId: arr.orgId }
        this.filterList.sysOrgAreaDTOList.push(li)
      }
      if (arr.childrenOrg.length != 0) {
        arr.childrenOrg.forEach((val) => {
          this.recursionListCounty(val)
        })
      }
      return
    },
    /// 递归获取学校名称及ID
    recursionListSchool(arr) {
      if (arr.orgType == 2) {
        let li = { name: arr.orgName, sysOrgSchoolId: arr.orgId }
        this.filterList.sysOrgSchoolDTOList.push(li)
      }
      if (arr.childrenOrg.length != 0) {
        arr.childrenOrg.forEach((val) => {
          this.recursionListSchool(val)
        })
      }
      return
    },
    candidatesQuerySysOrgArea() {
      this.candidates()
      let testInformation = {
        getExamPlace: 0,
        getSchool: 1,
        sysOrgAreaId: this.param.querySysOrgAreaId,
      }
      if (!!this.param.querySysOrgAreaId) {
        rootOr2(testInformation).then((res) => {
          if (res.success) {
            res.data.orgType = undefined
            this.filterList.sysOrgSchoolDTOList = []
            this.recursionListSchool(res.data)
          }
        })
      } else {
        this.getSchoolName()
      }
    },
    // 老师科目筛选
    // filterFun () {
    //   filterTeacher(this.examId).then(res => {
    //     console.log('老师信息筛选', res);
    //     if (res.success) {
    //       this.filterList = res.data
    //       this.param.querySubjectType = this.filterList.subjectList[0]
    //     }
    //   })
    // },
    singleChangeBtn(val, i) {
      this.tableDataRight[val].teacherType = i
      // 缓存重 让列表刷新
      this.keyF5 == 1 ? (this.keyF5 = 2) : (this.keyF5 = 1)
      this.temporaryListRight = []
    },
    setTeacherTypeBtn(i) {
      if (this.temporaryListRight.length == 0)
        return this.$message.warning('请先选择老师')
      switch (i) {
        case 1:
          console.log('组长')
          this.ReviseIdentity(1)
          break
        case 2:
          console.log('组员')
          this.ReviseIdentity(2)
          break
      }
    },
    // 修改身份
    ReviseIdentity(index) {
      // 选中的 this.temporaryListRight
      // 右边所有 this.tableDataRight
      // 修改身份
      this.temporaryListRight.forEach((val) => {
        for (let j = 0; j < this.tableDataRight.length; j++) {
          if (val.userId == this.tableDataRight[j].userId) {
            this.tableDataRight[j].teacherType = index
            break
          }
        }
      })
      // 取消选中
      this.$refs.tableDataRight.clearSelection()
    },
    // tableRowClassName ({ row, rowIndex }) {
    //   //把每一行的索引放进row
    //   row.index = rowIndex;
    // },
    rowClickLeft(row, column, event) {
      let refsElTable = this.$refs.tableDataLeft // 获取表格对象
      let findRow = this.temporaryListLeft.find(
        (c) => c.rowIndex == row.rowIndex
      ) //找到选中的行
      if (findRow) {
        refsElTable.toggleRowSelection(row, false) //如过重复选中，则取消选中
        return
      }
      refsElTable.toggleRowSelection(row, true) // 实现选中行中选中事件
    },
    rowStyleLeft({ row, rowIndex }) {
      Object.defineProperty(row, 'rowIndex', {
        //给每一行添加不可枚举属性rowIndex来标识当前行
        value: rowIndex,
        writable: true,
        enumerable: false,
      })
    },
    handleSelectionLeft(val) {
      this.temporaryListLeft = []
      this.temporaryListLeft = val
      console.log('被选中的', this.temporaryListLeft)
    },
    // rowClickRight(row, column, event) {
    //   let refsElTable = this.$refs.tableDataRight // 获取表格对象
    //   let findRow = this.temporaryListRight.find(
    //     (c) => c.rowIndex == row.rowIndex
    //   ) //找到选中的行
    //   if (findRow) {
    //     refsElTable.toggleRowSelection(row, false) //如过重复选中，则取消选中
    //     return
    //   }
    //   refsElTable.toggleRowSelection(row, true) // 实现选中行中选中事件
    // },
    rowStyleRight({ row, rowIndex }) {
      Object.defineProperty(row, 'rowIndex', {
        //给每一行添加不可枚举属性rowIndex来标识当前行
        value: rowIndex,
        writable: true,
        enumerable: false,
      })
    },
    handleSelectionRight(val) {
      console.log(val)
      this.temporaryListRight = []
      this.temporaryListRight = val
    },
    // 添加
    addRight() {
      this.temporaryListLeft.forEach((val) => {
        // 监考老师（1监考组长 2监考考试 3阅卷组长 4 阅卷老师）
        val.teacherType = 2
        this.param.excludeUserIdList.push(val.userId)
      })
      console.log(this.temporaryListLeft)
      this.tableDataRight.push(...this.temporaryListLeft)
      console.log('右边数据', this.tableDataRight)

      // 重新请求一遍
      this.candidates()
    },
    // 移除
    deleteRight() {
      // 需要删除的右边下标
      let deleteArr = []
      // 左边选中的userId
      let deleteUserId = []
      this.tableDataRight.forEach((val, i) => {
        // deleteUserId.push(val.userId)
        for (let index = 0; index < this.temporaryListRight.length; index++) {
          if (val.userId == this.temporaryListRight[index].userId) {
            deleteUserId.push(val.userId)
            deleteArr.unshift(i)
            break
          }
        }
      })
      // 过滤删掉的人员
      // console.log('deleteArr', deleteArr)
      deleteArr.forEach((val) => {
        this.tableDataRight.splice(val, 1)
      })
      // 最新
      // console.log(this.param.excludeUserIdList);
      // console.log(deleteUserId);
      let list = this.param.excludeUserIdList.filter(function (item) {
        return deleteUserId.indexOf(item) == -1
      })
      this.param.excludeUserIdList = list
      // 重新请求一遍
      this.candidates()
    },
    // 保存添加时过滤添加
    filtersRight() {
      this.submitData = []
      this.tableDataRight.forEach((val) => {
        let li = {
          teacherId: '',
          teacherType: '',
        }
        li.teacherId = val.userId
        li.teacherType = val.teacherType
        this.submitData.push(li)
      })
    },
    // 查询并回显
    async echoFunc() {
      await InvigilationTeacher(this.examId, 1, 10000, {}).then((res) => {
        console.log('查询并回显', res)
        if (res.success) {
          let { data } = res
          data.forEach((val) => {
            this.param.excludeUserIdList.push(val.teacherId)
            // console.log(val.teacherType);
            let li = {
              nickName: val.teacherName,
              subjectType: val.subjectType,
              // sysOrgAreaName: (val.sysOrgArea) ? (val.sysOrgArea.name) : "",
              // sysOrgSchoolName: (val.sysOrgSchool) ? (val.sysOrgSchool.name) : "",
              sysOrgAreaName: val.userOrgDTOList[0]
                ? val.userOrgDTOList[0].sysOrgAreaRegionName
                : '',
              sysOrgSchoolName: val.userOrgDTOList[0]
                ? val.userOrgDTOList[0].sysOrgSchoolName
                : '',
              userId: val.teacherId,
              teacherType: val.teacherType,
            }
            li.teacherType == 1
              ? this.tableDataRight.unshift(li)
              : this.tableDataRight.push(li)
          })
        }
      })
    },
    addAsk() {
      addTeacher(this.examId, this.submitData)
        .then((res) => {
          console.log('保存', res)
          this.saveBtnLoading = false
          if (res.success) {
            let { data } = res
            console.log('data', data)
            this.parentFunc()
          } else {
            this.$message.info(res.msg)
          }
        })
        .catch((err) => {
          this.saveBtnLoading = false
          this.$message.error('保存失败')
        })
    },
    renewAsk() {
      renewTeacher(this.examId, this.submitData)
        .then((res) => {
          console.log('更新', res)
          this.saveBtnLoading = false
          if (res.success) {
            let { data } = res
            console.log('data', data)
            this.parentFunc()
          } else {
            this.$message.info(res.msg)
          }
        })
        .catch((err) => {
          this.saveBtnLoading = false
          this.$message.error('保存失败')
        })
    },
    // 补考
    makeupInvigilationFunc() {
      makeupInvigilation(this.examId, this.submitData)
        .then((res) => {
          console.log('补考保存监考老师', res)
          this.saveBtnLoading = false
          if (res.success) {
            let { data } = res
            console.log('data', data)
            this.parentFunc()
          } else {
            this.$message.info(res.msg)
          }
        })
        .catch((err) => {
          this.saveBtnLoading = false
          this.$message.error('保存失败')
        })
    },
    saveBtn() {
      this.filtersRight()
      // 校验
      if (this.judgeData()) return
      this.saveBtnLoading = true
      if (this.$route.query.mainExamId) {
        this.makeupInvigilationFunc()
      } else {
        if (this.firstTime) {
          console.log('执行更新')
          this.renewAsk()
        } else {
          console.log('执行添加')
          this.addAsk()
        }
      }
    },
    // 判断数据
    judgeData() {
      if (!this.examId) {
        this.$message.warning('请先将基本信息模块的数据保存')
      } else if (!this.submitData.length) {
        this.$message.warning('已选人员不能为空')
      } else {
        return false
      }
      return true
    },
    // 触发父组件方法
    parentFunc() {
      this.$emit('parentFunc', 4)
    },
  },
  async mounted() {
    // 教师条件筛选
    if (this.examId) {
      this.subjectListFunc()
      this.getSchoolName()
    }
    if (this.firstTime) {
      // 同步执行
      await this.echoFunc()
      console.log('执行查询')
      await this.candidates()
    } else {
      // this.candidates()
    }
  },
  filters: {
    teacherTypeFilter(val) {
      switch (val) {
        case 1:
          return `监考组长`
        case 2:
          return `监考组员`
        case 3:
          return `阅卷组长`
        case 4:
          return `阅卷老师`
        default:
          return `${val}是错误的teacherType`
      }
    },
    subjectType(key) {
      switch (key) {
        case 1:
          return `物理`
        case 2:
          return `生物`
        case 3:
          return `化学`
        default:
          return `未知学科`
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-card__header {
  background: #dfe6ec;
}
::v-deep .titleText {
  text-align: center;
  margin-top: 40px;
  font-weight: bold;
  font-size: 18px;
}
::v-deep .el-card {
  margin: 40px 0;
  width: 680px;
  height: 600px;
  .treeHead {
    text-align: center;
    span {
      color: #ccc;
      font-size: 14px;
      float: right;
    }
  }
}
::v-deep .el-form--inline {
  margin: 10px 5px 0 5px;
  display: flex;
  justify-content: center;
}
::v-deep .el-form-item {
  // width: 25%;
  margin-right: 10px;
  margin-bottom: 10px;
}
::v-deep .el-form-item__label {
  padding-right: 5px;
}
.demo-form-inline {
  font-size: 12px !important;
}
::v-deep .demo-form-inline .el-form-item__content {
  width: 110px;
  input {
    padding: 6px;
  }
}
.capabilitiesBtn {
  text-align: center;
  display: flex;
}
</style>

<template>
  <div>
    <el-form
      ref="examArrangementBaseInfoParam"
      :model="examArrangementBaseInfoParam"
      label-width="90px"
      style="margin-top: 20px"
    >
      <el-form-item label="考试名称：">
        <el-input
          v-model="examArrangementBaseInfoParam.examName"
          style="width: 560px"
          placeholder="请输入本次考试名称"
          maxlength="40"
          :disabled="makeup"
        ></el-input>
      </el-form-item>
      <el-form-item label="考试编码：">
        <el-input
          v-model="examArrangementBaseInfoParam.examCode"
          style="width: 560px"
          placeholder="考试编码(限制18位)"
          :disabled="makeup"
          minlength="18"
          maxlength="18"
        ></el-input>
      </el-form-item>
      <el-form-item label="考试时间：">
        <!--  -->
        <!--  -->
        <el-date-picker
          popper-class="no-atTheMoment"
          style="width: 560px"
          v-model="examArrangementBaseInfoParam.examStartTime"
          class="elPicker"
          type="datetime"
          format="yyyy-MM-dd HH:mm"
          placeholder="请选择考试开始时间"
          value-format="timestamp"
          default-time="00:00:00"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="设置试卷：">
        <div style="margin-top: 2px">
          <el-radio-group
            v-model="examArrangementBaseInfoParam.examModel"
            :disabled="makeup"
          >
            <el-radio :label="1">无试卷</el-radio>
            <el-radio :label="2">电子试卷</el-radio>
            <!-- <el-radio :label="3">拍照录入试卷</el-radio> -->
          </el-radio-group>
        </div>
        <!-- <el-tooltip
          content="此功能仅适用于现场评分，选择此功能将不再调用考题中的电子实验报告，改由阅卷老师现场拍照记录实验报告"
          placement="bottom"
          effect="light"
        >
          <i class="el-icon-question"></i>
        </el-tooltip>-->
      </el-form-item>
      <el-form-item label="考试科目：">
        <!-- <el-checkbox-group
          v-model="examArrangementBaseInfoParam.examArrangementSubjectAndTitleParamList"
        >
          <el-checkbox :label="subjectList1" :checked="flagSubject1" @change="subjectCheckbox1">物理</el-checkbox>
          <el-checkbox :label="subjectList2" :checked="flagSubject2" @change="subjectCheckbox2">生物</el-checkbox>
          <el-checkbox :label="subjectList3" :checked="flagSubject3" @change="subjectCheckbox3">化学</el-checkbox>
        </el-checkbox-group>-->
        <el-checkbox-group
          :disabled="makeup"
          v-model="examArrChoice"
          style="display: inline; margin-right: 20px"
        >
          <el-checkbox
            :label="1"
            :checked="flagSubject1"
            @change="subjectCheckbox1"
            >物理</el-checkbox
          >
          <el-checkbox
            :label="2"
            :checked="flagSubject2"
            @change="subjectCheckbox2"
            >生物</el-checkbox
          >
          <el-checkbox
            :label="3"
            :checked="flagSubject3"
            @change="subjectCheckbox3"
            >化学</el-checkbox
          >
        </el-checkbox-group>
        <!-- <template v-if="examArrChoice.length >= 2">
          (
          <el-checkbox :disabled="makeup" v-model="manySubjectSame">两个科目同个时间段连续考，请勾选需要连续考的学科</el-checkbox>
          <template v-if="manySubjectSame">
            <el-checkbox v-if="flagSubject1" v-model="subjectList1.examContinuous">物理</el-checkbox>
            <el-checkbox v-if="flagSubject2" v-model="subjectList2.examContinuous">生物</el-checkbox>
            <el-checkbox v-if="flagSubject3" v-model="subjectList3.examContinuous">化学</el-checkbox>
          </template>)
        </template>-->
      </el-form-item>
      <el-row class="middleBoxSpacing">
        <!-- 物理 v-if="flagSubject1" -->
        <div>
          <el-card :body-style="{ padding: '0px' }">
            <div slot="header" class="clearfix topicTit">
              物理考题
              <span class="rf"
                >已选{{ subjectList1.subjectTestList.length }}题</span
              >
            </div>
            <!-- 循环题目 -->
            <div
              class="topicList"
              v-for="(item, i) in subjectList1Show"
              :key="item.testQuestionId"
            >
              <div class="topicListLeft ellipsis">
                {{ item.operationTestName }}
              </div>
              <i
                v-if="!makeup"
                class="el-icon-remove-outline"
                @click="singleDelete(1, i)"
              ></i>
            </div>
          </el-card>
          <el-row class="btnTopicList">
            <el-button
              v-throttle
              :disabled="makeup || !flagSubject1"
              @click="chooseQuestions(1)"
              type="primary"
              size="small"
              >选择</el-button
            >
            <el-button
              v-throttle
              :disabled="makeup || !flagSubject1"
              @click="empty(1)"
              type="danger"
              size="small"
              >清空</el-button
            >
          </el-row>
        </div>
        <!-- 生物  v-if="flagSubject2" -->
        <div>
          <el-card :body-style="{ padding: '0px' }">
            <div slot="header" class="clearfix topicTit">
              生物考题
              <span class="rf"
                >已选{{ subjectList2.subjectTestList.length }}题</span
              >
            </div>
            <div
              class="topicList"
              v-for="(item, i) in subjectList2Show"
              :key="item.testQuestionId"
            >
              <div class="topicListLeft ellipsis">
                {{ item.operationTestName }}
              </div>
              <i
                v-if="!makeup"
                class="el-icon-remove-outline"
                @click="singleDelete(2, i)"
              ></i>
            </div>
          </el-card>
          <el-row class="btnTopicList">
            <el-button
              v-throttle
              :disabled="makeup || !flagSubject2"
              @click="chooseQuestions(2)"
              type="primary"
              size="small"
              >选择</el-button
            >
            <el-button
              v-throttle
              :disabled="makeup || !flagSubject2"
              @click="empty(2)"
              type="danger"
              size="small"
              >清空</el-button
            >
          </el-row>
        </div>
        <!-- 化学 v-if="flagSubject3" -->
        <div>
          <el-card :body-style="{ padding: '0px' }">
            <div slot="header" class="clearfix topicTit">
              化学考题
              <span class="rf"
                >已选{{ subjectList3.subjectTestList.length }}题</span
              >
            </div>
            <div
              class="topicList"
              v-for="(item, i) in subjectList3Show"
              :key="item.testQuestionId"
            >
              <div class="topicListLeft ellipsis">
                {{ item.operationTestName }}
              </div>
              <i
                v-if="!makeup"
                class="el-icon-remove-outline"
                @click="singleDelete(3, i)"
              ></i>
            </div>
          </el-card>
          <el-row class="btnTopicList">
            <el-button
              v-throttle
              :disabled="makeup || !flagSubject3"
              @click="chooseQuestions(3)"
              type="primary"
              size="small"
              >选择</el-button
            >
            <el-button
              v-throttle
              :disabled="makeup || !flagSubject3"
              @click="empty(3)"
              type="danger"
              size="small"
              >清空</el-button
            >
          </el-row>
        </div>
      </el-row>
      <el-row class="middleBox">
        <el-button
          v-throttle
          type="primary"
          @click="saveBtn"
          size="medium"
          :loading="saveBtnLoading"
          >保存</el-button
        >
        <router-link to="/exam/examManage" replace style="margin-left: 30px">
          <el-button v-throttle size="medium">返回</el-button>
        </router-link>
      </el-row>
    </el-form>
    <!-- 弹框 -->
    <el-dialog
      title="选择考题"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <div class="middleBox chooseMiddleBox">
        <el-card :body-style="{ padding: '0px' }">
          <div slot="header" class="clearfix topicTit">
            <template v-if="subjectCheckList.length"
              >{{
                subjectCheckList[0].subjectType | subjectTypeFilters
              }}考题</template
            >
            <span class="rf">已选{{ checkList.length }}题</span>
          </div>
          <el-input
            style="margin: 10px; width: calc(100% - 20px)"
            placeholder="可输入关键进行搜索"
            @input="handleSearch(searchMsg)"
            v-model="searchMsg"
            clearable
          ></el-input>
          <!-- <el-checkbox
            class="chooseTopicList"
            v-for="val in checkList"
            :key="val.testQuestionId"
            :label="val"
            :checked="true"
          >{{val.operationTestName}}</el-checkbox>
          <el-divider></el-divider>-->
          <el-checkbox-group v-model="checkList">
            <el-checkbox
              class="chooseTopicList"
              v-for="val in subjectCheckList"
              :key="val.testQuestionId"
              :label="val"
              :checked="val.check"
              v-show="val.showLi"
              >{{ val.operationTestName }}</el-checkbox
            >
          </el-checkbox-group>

          <!-- :checked="itemHandleSelectionChange(val.testQuestionId)" -->
        </el-card>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          v-throttle
          type="primary"
          size="small"
          @click="confirmChoose()"
          >确 定</el-button
        >
        <el-button
          v-throttle
          type="danger"
          size="small"
          @click="
            () => {
              ;(centerDialogVisible = false), (searchMsg = '')
            }
          "
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getQuestions,
  addBase,
  inquireBase,
  inquireBaseRetake,
  renewBase,
} from '@/api/exam/exam.js'
import { makeupAdd, makeupAddUpdate } from '@/api/exam/exam.js'
export default {
  name: 'basicInformation',
  data() {
    return {
      // 补考
      makeup: false,
      saveBtnLoading: false,
      examId: '',
      // 模糊搜索
      searchMsg: '',
      centerDialogVisible: false,
      examArrangementBaseInfoParam: {
        // 考试学科和题目集合
        examArrangementSubjectAndTitleParamList: [
          // {
          //   subject: 0,
          //   subjectTestList: []
          // },
        ],
        examCode: '',
        examId: 0,
        // 考试模式 (1有纸考试 2无纸化实验报告 3实验报告拍照))
        examModel: 0,
        examName: '',
        // 	考试开始时间 时间戳
        examStartTime: '',
      },

      // 选中的考题类型
      examArrChoice: [],

      manySubjectSame: false,

      // 选中的学科
      flagSubject1: false,
      flagSubject2: false,
      flagSubject3: false,

      // 学科内容
      subjectList1: {
        // examContinuous: false,
        subject: 1,
        subjectTestList: [],
      },
      subjectList2: {
        // examContinuous: false,
        subject: 2,
        subjectTestList: [],
      },
      subjectList3: {
        // examContinuous: false,
        subject: 3,
        subjectTestList: [],
      },
      // 选中的学科
      subjectTypeNum: 0,
      // 展示的考题
      subjectList1Show: [],
      subjectList2Show: [],
      subjectList3Show: [],

      // 当前选中的考题
      checkList: [],
      // 考题列表
      subjectCheckList: [],
    }
  },
  methods: {
    itemHandleSelectionChange(row) {
      console.log(row)
      console.log(this.checkList)
      for (let index = 0; index < this.checkList.length; index++) {
        if (row == this.checkList[index].testQuestionId) {
          return true
        }
      }
      return false
    },
    forFunc(arr, arr2) {
      arr.forEach((val) => {
        val.check = false
        val.showLi = true
        for (let index = 0; index < arr2.length; index++) {
          if (val.testQuestionId == arr2[index].testQuestionId) {
            val.check = true
          }
        }
      })
      return arr.sort(function (a, b) {
        return b.check - a.check
      })
    },
    subjectCheckbox1(flag) {
      this.flagSubject1 = flag
    },
    subjectCheckbox2(flag) {
      this.flagSubject2 = flag
    },
    subjectCheckbox3(flag) {
      this.flagSubject3 = flag
    },

    // 可取消选中
    // clickItem (e) {
    //   e === this.examArrangementBaseInfoParam.examModel ? this.examArrangementBaseInfoParam.examModel = 0 : this.examArrangementBaseInfoParam.examModel = e
    // },
    // 选择考题
    chooseQuestions(i) {
      console.log('选择考题', i, this.checkList)
      this.subjectTypeNum = i
      // 清空选中的值
      this.subjectCheckList = []
      this.checkList = []
      // 考题数据请求
      let data = {
        subjectType: i,
        examModel: this.examArrangementBaseInfoParam.examModel,
      }
      getQuestions(data).then((res) => {
        console.log('res', res)
        if (res.success) {
          switch (i) {
            case 1:
              this.subjectCheckList = this.forFunc(
                res.data,
                this.subjectList1Show
              )
              break
            case 2:
              this.subjectCheckList = this.forFunc(
                res.data,
                this.subjectList2Show
              )
              break
            case 3:
              this.subjectCheckList = this.forFunc(
                res.data,
                this.subjectList3Show
              )
              break
            default:
              console.log(`奇奇怪怪的${i}`)
              break
          }
          this.centerDialogVisible = true
          console.log('this.subjectCheckList', this.subjectCheckList)
          console.log(this.checkList)
        }
      })
    },
    // 确认考题
    confirmChoose() {
      this.searchMsg = ''
      console.log('确认考题checkList', this.checkList)
      // 传值
      switch (this.subjectTypeNum) {
        case 1:
          // 显示
          this.subjectList1Show = JSON.parse(JSON.stringify(this.checkList))
          this.subjectList1.subjectTestList = []
          // 将值放进subjectList1
          this.subjectList1Show.forEach((val) => {
            this.subjectList1.subjectTestList.push(val.testQuestionId)
          })
          break
        case 2:
          this.subjectList2Show = JSON.parse(JSON.stringify(this.checkList))
          this.subjectList2.subjectTestList = []
          this.subjectList2Show.forEach((val) => {
            this.subjectList2.subjectTestList.push(val.testQuestionId)
          })
          break
        case 3:
          this.subjectList3Show = JSON.parse(JSON.stringify(this.checkList))
          this.subjectList3.subjectTestList = []
          this.subjectList3Show.forEach((val) => {
            this.subjectList3.subjectTestList.push(val.testQuestionId)
          })
          break
      }
      this.subjectTypeNum = 0
      this.centerDialogVisible = false
      // console.log('data', this.examArrangementBaseInfoParam)
    },
    // 移除单个考题
    singleDelete(subject, i) {
      switch (subject) {
        case 1:
          this.subjectList1.subjectTestList.splice(i, 1)
          this.subjectList1Show.splice(i, 1)
          return
        case 2:
          this.subjectList2.subjectTestList.splice(i, 1)
          this.subjectList2Show.splice(i, 1)
          break
        case 3:
          this.subjectList3.subjectTestList.splice(i, 1)
          this.subjectList3Show.splice(i, 1)
          break
      }
    },
    // 清空考题
    empty(i) {
      switch (i) {
        case 1:
          this.subjectList1.subjectTestList = []
          this.subjectList1Show = []
          break
        case 2:
          this.subjectList2.subjectTestList = []
          this.subjectList2Show = []
          break
        case 3:
          this.subjectList3.subjectTestList = []
          this.subjectList3Show = []
          break
      }
    },

    // 模糊搜索
    handleSearch(inp) {
      console.log('哦吼', !!inp.length)
      this.subjectCheckList.forEach((val) => {
        if (val.operationTestName.indexOf(inp) >= 0) {
          if (!inp.length || !val.check) {
            val.showLi = true
          } else {
            val.showLi = false
          }
        } else {
          val.showLi = false
        }
      })
    },
    // 考试科目回显处理
    echoFun(arrData) {
      this.examArrChoice = []
      arrData.forEach((val) => {
        this.examArrChoice.push(val.subject)
        switch (val.subject) {
          case 1:
            this.subjectCheckbox1(true)
            this.subjectList1.examContinuous = val.examContinuous
            break
          case 2:
            this.subjectCheckbox2(true)
            this.subjectList2.examContinuous = val.examContinuous
            break
          case 3:
            this.subjectCheckbox3(true)
            this.subjectList3.examContinuous = val.examContinuous
            break
          default:
            console.log(val.subject)
            break
        }
        this.subjectTypeNum = val.subject
        this.checkList = JSON.parse(JSON.stringify(val.subjectTestList))
        this.confirmChoose()
        this.checkList = []
      })
    },
    // 将考题放进
    setExamQuestions() {
      if (this.examArrChoice.length) {
        this.examArrangementBaseInfoParam.examArrangementSubjectAndTitleParamList =
          []
        this.examArrChoice.forEach((num) => {
          switch (num) {
            case 1:
              this.examArrangementBaseInfoParam.examArrangementSubjectAndTitleParamList.push(
                this.subjectList1
              )
              break
            case 2:
              this.examArrangementBaseInfoParam.examArrangementSubjectAndTitleParamList.push(
                this.subjectList2
              )
              break
            case 3:
              this.examArrangementBaseInfoParam.examArrangementSubjectAndTitleParamList.push(
                this.subjectList3
              )
              break

            default:
              console.log('num错误')
              break
          }
        })
      }
    },
    // 保存
    saveBtn() {
      this.setExamQuestions()
      // 校验
      if (this.judgeData()) return
      this.saveBtnLoading = true
      if (this.$route.query.mainExamId) {
        if (this.$route.query.examId) {
          // 更新保存
          this.renewAskMakeup()
        } else {
          // 第一次保存
          this.addAskMakeup()
        }
      } else {
        if (this.examId) {
          this.renewAsk()
        } else {
          this.addAsk()
        }
      }

      console.log(
        'examArrangementBaseInfoParam',
        this.examArrangementBaseInfoParam
      )
    },

    // 查询
    inquireAsk() {
      inquireBase(this.examId).then((res) => {
        console.log('res', res)
        if (res.success) {
          let { data } = res
          // console.log(res);
          this.examArrangementBaseInfoParam.examCode = data.examCode
          this.examArrangementBaseInfoParam.examName = data.examName
          this.examArrangementBaseInfoParam.examStartTime = data.examStartTime
          // 判读补考是否未保存 只是进入
          if (!!this.$route.query.mainExamId && !this.$route.query.examId) {
            this.examArrangementBaseInfoParam.examStartTime = ''
          }
          this.examArrangementBaseInfoParam.examModel = data.examModel
          // this.manySubjectSame = data.manySubjectSame

          this.examArrChoice = data.manySubjectSame
          // 处理选中的考题
          this.echoFun(data.examArrangementSubjectAndTitleDTOList)
        }
      })
    },
    // 补考查询
    inquireAskRetake() {
      inquireBaseRetake(this.examId).then((res) => {
        console.log('res', res)
        if (res.success) {
          let { data } = res
          // console.log(res);
          this.examArrangementBaseInfoParam.examCode = data.examCode
          this.examArrangementBaseInfoParam.examName = data.examName
          this.examArrangementBaseInfoParam.examStartTime = data.examStartTime
          //   // 判读补考是否未保存 只是进入
          if (!!this.$route.query.mainExamId && !this.$route.query.examId) {
            this.examArrangementBaseInfoParam.examStartTime = ''
          }
          this.examArrangementBaseInfoParam.examModel = data.examModel
          //   // this.manySubjectSame = data.manySubjectSame
          this.examArrChoice = data.manySubjectSame
          //   // 处理选中的考题
          this.echoFun(data.examSubjectAndTitleDTOList)
        }
      })
    },
    // 添加
    addAsk() {
      addBase(this.examArrangementBaseInfoParam)
        .then((res) => {
          this.saveBtnLoading = false
          if (res.success) {
            console.log('基本信息data', res)
            this.$router.replace(`/exam/examAdd?examId=${res.data.examId}`)
            this.parentFunc()
          } else {
            this.$message.info(res.msg)
          }
        })
        .catch((err) => {
          this.saveBtnLoading = false
          this.$message.error('添加失败')
        })
    },
    // 更新
    renewAsk() {
      renewBase(this.examId, this.examArrangementBaseInfoParam)
        .then((res) => {
          console.log('更新基本信息', res)
          this.saveBtnLoading = false
          if (res.success) {
            this.parentFunc()
          } else {
            this.$message.info(res.msg)
          }
        })
        .catch((err) => {
          this.saveBtnLoading = false
          this.$message.error('更新失败')
        })
    },
    // 补考添加
    addAskMakeup() {
      let data = {
        examStartTime: this.examArrangementBaseInfoParam.examStartTime,
        mainExamId: this.$route.query.mainExamId,
        parentExamId: this.$route.query.parentExamId,
      }
      makeupAdd(data)
        .then((res) => {
          console.log('补考添加', res)
          this.saveBtnLoading = false
          if (res.success) {
            this.$router.replace({
              path: '/exam/examAdd',
              query: {
                mainExamId: this.$route.query.mainExamId,
                parentExamId: this.$route.query.parentExamId,
                examId: res.data.examId,
              },
            })
            this.parentFunc()
          } else {
            this.$message.info(res.msg)
          }
        })
        .catch((err) => {
          this.saveBtnLoading = false
          this.$message.error('添加失败')
        })
    },
    // 补考更新
    renewAskMakeup() {
      let data = {
        examStartTime: this.examArrangementBaseInfoParam.examStartTime,
        mainExamId: this.$route.query.mainExamId,
        parentExamId: this.$route.query.parentExamId,
      }
      makeupAddUpdate(this.$route.query.examId, data)
        .then((res) => {
          console.log('补考更新', res)
          this.saveBtnLoading = false
          if (res.success) {
            this.parentFunc()
          } else {
            this.$message.info(res.msg)
          }
        })
        .catch((err) => {
          this.saveBtnLoading = false
          this.$message.error('更新失败')
        })
    },
    // 判断数据 if (this.judgeData()) return
    judgeData() {
      if (this.manySubjectSame) {
        let num = 0
        if (this.subjectList1.examContinuous) {
          num += 1
        }
        if (this.subjectList2.examContinuous) {
          num += 1
        }
        if (this.subjectList3.examContinuous) {
          num += 1
        }
        if (num <= 1) {
          this.$message.warning('选中的连续考试科目要大于等于2')
          return true
        }
      }
      if (
        this.examArrangementBaseInfoParam
          .examArrangementSubjectAndTitleParamList.length != 0
      ) {
        let flag
        this.examArrangementBaseInfoParam.examArrangementSubjectAndTitleParamList.forEach(
          (val) => {
            console.log(val.subjectTestList.length)
            if (val.subjectTestList.length == 0) {
              this.$message.warning('考题不能为空')
              return (flag = true)
            }
          }
        )
        if (flag) return flag
      }
      if (!this.examArrangementBaseInfoParam.examName) {
        this.$message.warning('考试名称不能为空')
        console.log('异常')
      } else if (!this.examArrangementBaseInfoParam.examCode) {
        this.$message.warning('考试编码不能为空')
        console.log('异常')
      } else if (!this.examArrangementBaseInfoParam.examModel) {
        this.$message.warning('设置试卷不能为空')
        console.log('异常')
      } else if (!this.examArrangementBaseInfoParam.examStartTime) {
        this.$message.warning('考试时间不能为空')
        console.log('异常')
      } else if (
        this.examArrangementBaseInfoParam.examStartTime < Date.parse(new Date())
      ) {
        this.$message.warning('考试时间不能设为过去时间')
      } else if (!this.examArrChoice.length) {
        this.$message.warning('考试科目不能为空')
        console.log('异常')
      } else {
        return false
      }
      return true
    },
    // 触发父组件方法
    parentFunc() {
      this.$emit('parentFunc', 1)
    },
  },
  mounted() {
    if (this.$route.query.mainExamId) {
      this.makeup = true
      if (this.$route.query.examId) {
        this.examId = this.$route.query.examId
        this.inquireAsk()
      } else {
        this.examId = this.$route.query.mainExamId
        this.inquireAskRetake()
      }
    } else {
      if (this.$route.query.examId) {
        this.examId = this.$route.query.examId
        this.inquireAsk()
      } else {
        this.examArrangementBaseInfoParam.examCode = Math.random()
          .toString(36)
          .slice(2)
          .substr(-10)
      }
    }
  },
  watch: {
    // manySubjectSame (val) {

    //   if (val) {
    //     if (this.examArrChoice.length == 2) {
    //       console.log(this.examArrChoice);
    //       this.subjectList1.examContinuous = true
    //       this.subjectList2.examContinuous = true
    //       this.subjectList3.examContinuous = true
    //     }
    //   } else {
    //     this.subjectList1.examContinuous = val
    //     this.subjectList2.examContinuous = val
    //     this.subjectList3.examContinuous = val
    //   }
    // }
    'examArrangementBaseInfoParam.examModel': function (n, o) {
      if (o != 0 && Math.abs(n - o) == 1) {
        console.log('改动', o, n)
        this.empty(1)
        this.empty(2)
        this.empty(3)
      }
    },
  },
  filters: {
    subjectTypeFilters(key) {
      switch (key) {
        case 1:
          return `物理`
        case 2:
          return `生物`
        case 3:
          return `化学`
        default:
          return `subjectType值为${key}`
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-icon-question {
  margin-left: 5px;
  cursor: pointer;
  border-radius: 50%;
  color: #999;
}

::v-deep .middleBox {
  margin-top: 20px;
}

::v-deep .el-card {
  width: 400px !important;
}

::v-deep .el-card__body {
  height: 320px !important;
  overflow-y: auto;
}
::v-deep .el-card__header {
  background: #dfe6ec;
}
::v-deep .el-form-item__label {
  color: #000;
}
::v-deep .topicTit {
  text-align: center;
  background: #dfe6ec;
  span {
    color: #999;
    font-size: 14px;
  }
}

.topicList {
  box-sizing: border-box;
  padding: 0 20px;
  display: flex;
  height: 30px;
  line-height: 30px;
  align-items: center;
  justify-content: space-between;

  .topicListLeft {
    width: calc(100% - 20px);
  }

  i {
    color: red;
    cursor: pointer;
  }

  &:hover {
    background: rgba(204, 204, 204, 0.568);
  }
}

.btnTopicList {
  display: flex;
  margin-top: 10px;
  justify-content: flex-end;
}

::v-deep.chooseMiddleBox .el-card__body {
  height: 500px !important;
  overflow-y: auto;
}

::v-deep .chooseTopicList {
  box-sizing: border-box;
  padding: 0 20px;
  display: flex;
  align-items: center;
  height: 30px;
  line-height: 30px;
  width: 100%;

  .el-checkbox__label {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
  }
}
</style>

<template>
  <div v-loading="saveBtnLoading" element-loading-text="添加中">
    <!--  -->
    <template v-if="!makeup">
      <el-form
        ref="formListData"
        :model="formListData"
        style="margin-top: 20px"
        @submit.native.prevent
        class="listColor"
      >
        <el-form-item label="添加方式：">
          <el-radio-group v-model="formListData.type">
            <el-radio :label="1">按学校添加批量考生</el-radio>
            <el-radio :label="2">按考生编号添加单个考生</el-radio>
            <!-- <el-radio :label="2">按考生编号添加批量考生</el-radio> -->
          </el-radio-group>
        </el-form-item>
        <template v-if="formListData.type == 2">
          <el-form-item label="考生编号：">
            <el-input
              v-model.trim="formListData.studentCode"
              style="width: 560px"
              placeholder="请输入考生编号"
            ></el-input>
          </el-form-item>
        </template>
        <template v-else>
          <el-form-item label="考生学校：">
            <el-select
              v-model="formListData.orgSchoolId"
              placeholder="请选择学校"
              style="width: 560px"
            >
              <el-option
                v-for="item in formData.studentNumber"
                :key="item.orgId"
                :label="item.orgName"
                :value="item.orgId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="毕业年份：">
            <el-select
              v-model="formListData.graduationYear"
              placeholder="请选择毕业年份"
              style="width: 560px"
            >
              <el-option label="全部" :value="0"></el-option>
              <el-option
                v-for="item in formData.years"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
        </template>
        <!-- <el-row>
          <el-form-item label="考生排考方式">
            <el-switch
              disabled
              v-model="formListData.mockExam"
              active-text="校内模拟考"
            ></el-switch>
          </el-form-item>
        </el-row> -->

        <el-form-item>
          <el-button
            v-throttle
            size="medium"
            @click="addBtn"
            type="primary"
            :loading="saveBtnLoading"
            >添加</el-button
          >
        </el-form-item>
      </el-form>
    </template>
    <template v-else>
      <div style="margin: 20px 0">
        <el-button
          v-throttle
          size="medium"
          @click="makeupAddBtn"
          type="primary"
          :loading="saveBtnLoading"
          >一键添加考生</el-button
        >
        <el-button
          v-throttle
          size="medium"
          type="primary"
          @click="retakeUploadExamineeBtn"
          icon="el-icon-upload2"
          >导入补考考生</el-button
        >
      </div>
    </template>
    <!-- 操作 -->
    <el-row class="inputColor">
      <el-form
        :inline="true"
        :model="examineeQueryParam"
        class="demo-form-inline"
      >
        <el-button
          v-throttle
          type="primary"
          @click="uploadExamineeBtn"
          size="medium"
          v-if="!makeup"
          :disabled="!examId"
          >导入考生</el-button
        >
        <el-button
          v-throttle
          type="primary"
          @click="DownloadTemplateBtn"
          size="medium"
          v-if="!makeup"
          :disabled="!examId"
          >下载导入模板</el-button
        >
        <el-button
          v-throttle
          type="danger"
          @click="deleteBatchBtn"
          size="medium"
          >批量移除</el-button
        >
        <el-button
          v-throttle
          type="danger"
          @click="deleteAllBtn"
          size="medium"
          style="margin-right: 10px"
          >全体清空</el-button
        >
        <el-form-item label="姓名" class="formItemBoxStyle">
          <el-input
            @change="conditionChange"
            v-model="examineeQueryParam.studentName"
          ></el-input>
        </el-form-item>
        <el-form-item label="学号" class="formItemBoxStyle">
          <el-input
            @change="conditionChange"
            v-model="examineeQueryParam.studentCode"
          ></el-input>
        </el-form-item>

        <el-form-item label="区县" class="formItemBoxStyle">
          <el-select
            @change="conditionChange"
            v-model="examineeQueryParam.orgAreaId"
            placeholder="全部"
          >
            <el-option label="全部" :value="0"></el-option>
            <el-option
              v-for="item in inquireList.sysOrgAreaList"
              :key="item.sysOrgAreaId"
              :label="item.name"
              :value="item.sysOrgAreaId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学校" class="formItemBoxStyle">
          <el-select
            @change="conditionChange"
            v-model="examineeQueryParam.orgSchoolId"
            placeholder="全部"
          >
            <el-option label="全部" :value="0"></el-option>
            <el-option
              v-for="item in inquireList.sysOrgSchoolList"
              :key="item.sysOrgSchoolId"
              :label="item.name"
              :value="item.sysOrgSchoolId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="毕业年份" class="formItemBoxStyle">
          <el-select
            @change="conditionChange"
            v-model="examineeQueryParam.graduationYear"
            placeholder="全部"
          >
            <el-option label="全部" :value="0"></el-option>
            <el-option
              v-for="item in inquireList.graduationYearList"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-button v-throttle type="primary" @click="showExaminee(false)"
          >查询</el-button
        >
        <el-button v-throttle @click="clearBtn">重置</el-button>
      </el-form>
    </el-row>

    <!-- 表格 -->
    <el-row>
      <el-table
        header-align="center"
        border
        :header-cell-style="{ background: '#DFE6EC' }"
        :data="examineeUl"
        stripe
        style="width: 100%"
        @selection-change="handleSelection($event)"
      >
        <el-table-column align="center" type="selection"></el-table-column>
        <el-table-column
          align="center"
          prop="studentName"
          label="姓名"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="studentCode"
          label="学号"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="sysOrgAreaName"
          label="区县"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="sysOrgSchoolName"
          label="学校"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="sysOrgSchoolClassName"
          label="班级"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="graduationYear"
          label="毕业年份"
          width="120"
        ></el-table-column>
        <el-table-column align="center" label="证件类型" width="180">
          <template slot-scope="scope">{{
            scope.row.idCardType | idCardTypeFilters
          }}</template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="idCard"
          label="证件号码"
        ></el-table-column>
        <el-table-column align="center" label="补考学科" v-if="makeup">
          <template slot-scope="scope">{{
            scope.row.subjectType | subjectTypeFilter
          }}</template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="120">
          <template slot-scope="scope">
            <el-button
              v-throttle
              type="danger"
              size="mini"
              @click="deleteBtn(scope.row)"
              >移除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="tableDataPagination.pageIndex"
        :page-size="tableDataPagination.pageSize"
        :total="tableDataPagination.total"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </el-row>
    <el-row class="middleBox">
      <el-button v-throttle type="primary" @click="saveBtn" size="medium"
        >保存</el-button
      >
      <router-link to="/exam/examManage" replace style="margin-left: 30px">
        <el-button v-throttle size="medium">返回</el-button>
      </router-link>
    </el-row>
    <upload-excel-com
      @uploadFunc="uploadBtn"
      ref="uploadExcelCom"
      :uploadMsg="uploadMsg"
      :loading="uploadLoading"
    ></upload-excel-com>
  </div>
</template>

<script>
import {
  rootOr,
  orgSchoolId,
  conditionalAdd,
  conditionList,
  examineeList,
  addExaminee,
  deleteExaminee,
  renewAddExaminee,
  conditionalRenewExaminee,
  importExamMakeupExamineeExcelApi,
} from '@/api/exam/exam.js'
import {
  makeupExaminee,
  makeupExamineeDelete,
  makeupExamineeList,
  uploadExamineeApi,
  DownloadTemplateApi,
} from '@/api/exam/exam.js'
export default {
  name: 'candidateArrangement',
  props: ['examId', 'firstTime'],
  data() {
    return {
      retake: false,
      uploadMsg: '',
      uploadLoading: false,
      // 补考
      makeup: false,
      saveBtnLoading: false,
      // 请求信息（学校）
      testInformation: {
        getExamPlace: 0,
        getSchool: 1,
      },
      // 页面显示
      formData: {
        studentNumber: [],
        years: [],
      },
      // 添加考生的条件（提交）
      formListData: {
        // 毕业年份
        graduationYear: '',
        matchedPattern: 1,
        // 学校id
        orgSchoolId: '',
        // 	学生编号
        studentCode: '',
        // 查询类型（1按学校 2按编号）
        type: 1,
      },
      // 考生过滤条件
      examineeQueryParam: {
        graduationYear: 0,
        orgAreaId: 0,
        orgSchoolId: 0,
        studentCode: '',
        studentName: '',
      },
      inquireList: {},
      // 分页
      tableDataPagination: {
        // 当前页
        pageIndex: 1,
        // 页大小
        pageSize: 10,
        // 页数
        pageTotal: 1,
        // 总数量
        total: 0,
      },
      // 显示
      examineeUl: [],
      // 提交
      examineeSubmit: [],
      // 删除列表
      deleteList: [],
    }
  },
  methods: {
    // 导入考试考生excel
    uploadExamineeBtn() {
      this.retake = false
      this.$refs.uploadExcelCom.dialogBox = true
      this.uploadMsg = ''
    },
    // 补考
    retakeUploadExamineeBtn() {
      this.retake = true
      this.$refs.uploadExcelCom.dialogBox = true
      this.uploadMsg = ''
    },
    // 导入
    uploadBtn(fd) {
      this.uploadLoading = true
      // let fd = new FormData()
      // fd.append('file', item.file)
      if (this.retake) {
        importExamMakeupExamineeExcelApi(this.examId, fd)
          .then((res) => {
            this.uploadMsg = ''
            console.log('res', res)
            if (res.success) {
              // this.$message.success('导入成功');
              this.$message({
                showClose: true,
                message: '导入成功',
                type: 'success',
                duration: 10000,
              })
              this.filterCondition()
              this.showExaminee()
              this.$refs.uploadExcelCom.handleClose()
            } else {
              this.$message.warning(res.msg)
              this.uploadMsg = res.msg
              this.$refs.uploadExcelCom.clearFile()
            }
            this.uploadLoading = false
          })
          .catch((err) => {
            this.uploadLoading = false
          })
      } else {
        uploadExamineeApi(this.examId, fd)
          .then((res) => {
            this.uploadMsg = ''
            console.log('res', res)
            if (res.success) {
              // this.$message.success('导入成功');
              this.$message({
                showClose: true,
                message: '导入成功',
                type: 'success',
                duration: 10000,
              })
              this.filterCondition()
              this.showExaminee()
              this.$refs.uploadExcelCom.handleClose()
            } else {
              this.$message.warning(res.msg)
              this.uploadMsg = res.msg
              this.$refs.uploadExcelCom.clearFile()
            }
            this.uploadLoading = false
          })
          .catch((err) => {
            this.uploadLoading = false
          })
      }
    },
    // 下载导入模板
    DownloadTemplateBtn() {
      DownloadTemplateApi().then((res) => {
        console.log('下载excel模板', res)
        if (res.success) {
          this.$message.success('下载成功')
          window.open(this.$apiAddress(res.data))
        } else {
          this.$message.error('下载失败：' + res.msg)
        }
      })
    },
    // 获取学校名称
    getSchoolName() {
      if (this.formData.studentNumber.length != 0) return
      rootOr(this.testInformation).then((res) => {
        console.log('获取学校名称', res)
        if (res.success) {
          this.recursionList(res.data)
        }
      })
    },
    // 获取年份
    getYears() {
      this.formListData.graduationYear = ''
      orgSchoolId(this.formListData.orgSchoolId).then((res) => {
        this.formData.years = []
        if (res.success) {
          this.formData.years = res.data
        }
      })
    },
    // 递归获取学校名称及ID
    recursionList(arr) {
      if (arr.orgType == 2) {
        let li = { orgName: arr.orgName, orgId: arr.orgId }
        this.formData.studentNumber.push(li)
      }
      if (arr.childrenOrg.length != 0) {
        arr.childrenOrg.forEach((val) => {
          this.recursionList(val)
        })
      }
      return
    },
    // 查询考生过滤条件
    filterCondition() {
      conditionList(this.examId).then((res) => {
        console.log(res)
        if (res.success) {
          this.inquireList = res.data
        }
      })
    },
    // 查询考生
    showExaminee(makeupAdd = false) {
      this.deleteList = []
      console.log(this.examineeQueryParam.graduationYear)
      // if (this.examineeQueryParam.graduationYear == 0) { delete this.examineeQueryParam.graduationYear }
      // if (this.examineeQueryParam.orgAreaId == 0) { delete this.examineeQueryParam.orgAreaId }
      // if (this.examineeQueryParam.orgSchoolId == 0) { delete this.examineeQueryParam.orgSchoolId }
      // if (this.examineeQueryParam.studentCode == "") { delete this.examineeQueryParam.studentCode }
      // if (this.examineeQueryParam.studentName == "") { delete this.examineeQueryParam.studentName }
      for (let key in this.examineeQueryParam) {
        if (
          this.examineeQueryParam[key] == 0 ||
          this.examineeQueryParam[key] == '' ||
          this.examineeQueryParam[key] == undefined
        ) {
          delete this.examineeQueryParam[key]
        }
      }
      console.log('this.examineeQueryParam', this.examineeQueryParam)
      if (this.$route.query.mainExamId) {
        makeupExamineeList(
          this.examId,
          this.tableDataPagination.pageIndex,
          this.tableDataPagination.pageSize,
          this.examineeQueryParam
        )
          .then((res) => {
            if (makeupAdd && !!res.data.length) {
              this.$message.success('添加成功')
            }
            console.log('学生', res)
            this.examineeUl = res.data
            this.tableDataPagination.pageTotal = res.pageTotal
            this.tableDataPagination.total = res.total
            this.saveBtnLoading = false
          })
          .catch((err) => {
            this.saveBtnLoading = false
          })
      } else {
        examineeList(
          this.examId,
          this.tableDataPagination.pageIndex,
          this.tableDataPagination.pageSize,
          this.examineeQueryParam
        )
          .then((res) => {
            console.log('学生', res)
            this.examineeUl = res.data
            this.tableDataPagination.pageTotal = res.pageTotal
            this.tableDataPagination.total = res.total
            this.saveBtnLoading = false
          })
          .catch((err) => {
            this.saveBtnLoading = false
          })
      }
    },
    // 添加按钮
    addBtn() {
      if (this.formListData.orgSchoolId == '' && this.formListData.type == 1)
        return this.$message.warning('考生学校为必选项')
      if (this.formListData.studentCode == '' && this.formListData.type == 2)
        return this.$message.warning('考生编号为必填项')
      if (
        this.formListData.graduationYear == '' ||
        this.formListData.graduationYear == 0
      ) {
        this.formListData.graduationYear = ''
      }
      this.saveBtnLoading = true
      if (this.firstTime) {
        console.log('执行更新')
        this.renewAsk()
      } else {
        console.log('执行添加')
        this.addAsk()
      }
    },
    // 一键添加补考考生
    makeupAddBtn() {
      if (!this.examId) {
        this.$message.warning('请先将基本信息模块的数据保存')
      }
      makeupExaminee(this.examId)
        .then((res) => {
          console.log('res', res)
          this.saveBtnLoading = false
          if (res.success) {
            // 触发过滤条件
            this.filterCondition()
            this.showExaminee(true)
          } else {
            this.$message.warning(res.msg)
          }
        })
        .catch((err) => {
          this.$message.error('添加失败')
          this.saveBtnLoading = false
        })
    },
    // console.log(`每页 ${val} 条`);
    handleSizeChange(val) {
      this.tableDataPagination.pageIndex = 1
      this.tableDataPagination.pageSize = val
      this.showExaminee()
      // this.inquire(this.tableDataPagination)
    },
    // console.log(`当前页: ${val}`);
    handleCurrentChange(val) {
      this.tableDataPagination.pageIndex = val
      this.showExaminee()
      // this.inquire(this.tableDataPagination)
    },
    conditionChange() {
      console.log('改变条件')
      this.tableDataPagination.pageIndex = 1
      this.showExaminee()
    },
    // 批量选中处理
    handleSelection(val) {
      if (val.length) {
        this.deleteList = []
        if (this.$route.query.mainExamId) {
          val.forEach((val) => {
            this.deleteList.push(val.makeupExamineeId)
          })
        } else {
          val.forEach((val) => {
            this.deleteList.push(val.studentId)
          })
        }

        console.log(this.deleteList)
      }
    },
    // 单个删除
    deleteBtn(val) {
      this.$confirm('确认删除该考生?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          if (this.$route.query.mainExamId) {
            makeupExamineeDelete(this.examId, [val.makeupExamineeId]).then(
              (res) => {
                console.log('移除考生', res)
                if (res.success) {
                  this.tableDataPagination.pageIndex = 1
                  this.showExaminee()
                  this.$message({
                    type: 'success',
                    message: '删除成功!',
                  })
                }
              }
            )
          } else {
            deleteExaminee(this.examId, [val.studentId]).then((res) => {
              console.log('移除考生', res)
              if (res.success) {
                this.tableDataPagination.pageIndex = 1
                this.showExaminee()
                this.$message({
                  type: 'success',
                  message: '删除成功!',
                })
              }
            })
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    // 批量删除
    deleteBatchBtn() {
      if (this.deleteList.length == 0)
        return this.$message.warning('请选中删除的考生')
      if (this.examineeUl.length == 0)
        return this.$message.warning('无删除考生')
      this.$confirm('确认批量删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          if (this.$route.query.mainExamId) {
            makeupExamineeDelete(this.examId, this.deleteList).then((res) => {
              console.log('批量删除', res)
              if (res.success) {
                this.tableDataPagination.pageIndex = 1
                // 删除后重新查询
                this.showExaminee()
                this.$message({
                  type: 'success',
                  message: '删除成功!',
                })
              }
            })
          } else {
            deleteExaminee(this.examId, this.deleteList).then((res) => {
              console.log('批量删除', res)
              if (res.success) {
                this.tableDataPagination.pageIndex = 1
                // 删除后重新查询
                this.showExaminee()
                this.$message({
                  type: 'success',
                  message: '删除成功!',
                })
              }
            })
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    // 全部删除
    deleteAllBtn() {
      if (!this.examineeUl.length) return this.$message.warning('无考生可清空')
      this.$confirm('确认全体清空?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.deleteList = []
          if (this.$route.query.mainExamId) {
            makeupExamineeDelete(this.examId, []).then((res) => {
              console.log('全部删除', res)
              if (res.success) {
                this.tableDataPagination.pageIndex = 1
                this.showExaminee()
                this.$message({
                  type: 'success',
                  message: '删除成功!',
                })
              }
            })
          } else {
            deleteExaminee(this.examId, []).then((res) => {
              console.log('全部删除', res)
              if (res.success) {
                this.tableDataPagination.pageIndex = 1
                this.showExaminee()
                this.$message({
                  type: 'success',
                  message: '删除成功!',
                })
              }
            })
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    // 清除按钮
    clearBtn() {
      this.examineeQueryParam = {
        graduationYear: 0,
        orgAreaId: 0,
        orgSchoolId: 0,
        studentCode: '',
        studentName: '',
      }
      this.tableDataPagination.pageIndex = 1
      this.tableDataPagination.pageSize = 10
      this.showExaminee()
    },
    // 添加
    addAsk() {
      if (!this.examId) {
        this.$message.warning('请先将基本信息模块的数据保存')
      }
      // 新增
      conditionalAdd(this.examId, this.formListData)
        .then((res) => {
          console.log('res', res)
          if (res.success) {
            // 触发过滤条件
            this.filterCondition()
            this.showExaminee()
          } else {
            this.saveBtnLoading = false
            this.$message.warning(res.msg)
          }
          this.$emit('parentFunc', 4)
        })
        .catch((err) => {
          this.$message.error('添加失败')
          this.saveBtnLoading = false
        })
    },
    // 更新
    renewAsk() {
      conditionalAdd(this.examId, this.formListData)
        .then((res) => {
          console.log('res', res)
          if (res.success) {
            // 触发过滤条件
            this.filterCondition()
            this.showExaminee()
          } else {
            this.$message.warning(res.msg)
            this.saveBtnLoading = false
          }
          this.$emit('parentFunc', 4)
        })
        .catch((err) => {
          this.$message.error('添加失败')
          this.saveBtnLoading = false
        })
    },
    saveBtn() {
      // if (this.firstTime) {
      //   console.log('执行更新')
      //   // this.renewAsk()
      // } else {
      //   console.log('执行添加')
      //   // this.addAsk()
      // }
      // 校验
      if (this.judgeData()) return
      this.parentFunc()
    },
    // 判断数据
    judgeData() {
      if (!this.examId) {
        this.$message.warning('请先将基本信息模块的数据保存')
      } else if (!this.examineeUl.length) {
        this.$message.warning('考生不能为空')
      } else {
        return false
      }
      return true
    },
    // 触发父组件方法
    parentFunc() {
      this.$emit('parentFunc', 5)
    },
  },
  mounted() {
    console.log('firstTime', this.firstTime)
    this.getSchoolName()
    if (this.$route.query.mainExamId) {
      this.makeup = true
      if (this.$route.query.examId) {
        this.filterCondition()
        this.showExaminee()
      }
    } else {
      if (this.firstTime) {
        console.log('执行查询-')
        this.filterCondition()
        this.showExaminee()
      }
    }
  },
  watch: {
    'formListData.type': (val) => {
      !val && this.getSchoolName()
    },
    'formListData.orgSchoolId'(val) {
      if (val != '') {
        this.getYears()
      }
    },
  },
  filters: {
    idCardTypeFilters(key) {
      switch (key) {
        case 1:
          return `身份证`
        case 2:
          return `港澳居民往来内地通行证`
        case 3:
          return `台湾居民往来内地通行证`
        case 4:
          return `护照`
        default:
          return `证件类型错误:${key}`
      }
    },
    subjectTypeFilter(val) {
      // let sub = ''
      // if (val !== undefined && val != null && val.length > 0) {
      //   for (let i = 0; i < val.length; i++) {
      //     switch (val[i]) {
      //       case 1: sub += `物理`
      //         break;
      //       case 2: sub += `生物`
      //         break;
      //       case 3: sub += `化学`
      //         break;
      //       default: sub += `错误的值${key}`
      //         break;
      //     }
      //     if (i + 1 < val.length) {
      //       sub += ","
      //     }
      //   }
      // }
      // return sub
      switch (val) {
        case 1:
          return `物理`
        case 2:
          return `生物`
        case 3:
          return `化学`
        default:
          return `错误的值${key}`
      }
    },
  },
}
</script>

<style scoped>
::v-deep .inputColor input::-webkit-input-placeholder {
  color: #606266 !important;
}

.listColor ::v-deep .el-form-item__label {
  color: #000;
}
</style>

<template>
  <div>
    <el-form
      ref="examArrangementRuleParam"
      :model="examArrangementRuleParam"
      label-width="90px"
      style="margin-top: 20px"
    >
      <el-form-item label="排考方式：">
        <el-radio-group
          :disabled="makeup"
          v-model="examArrangementRuleParam.arrangeModel"
          @change="arrangeModelHandoffFunc"
        >
          <el-radio :label="1">定时定点</el-radio>
          <el-radio :label="2">随到随考</el-radio>
          <el-radio :label="3">定点不定时</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-show="
          examArrangementRuleParam.examArrangementSubjectContinuousParamList
            .length >= 2
        "
        label="多科连考："
      >
        <el-switch
          v-model="coiled"
          @change="coiledFunc"
          style="margin-right: 10px"
        ></el-switch>
        <template v-if="coiled">
          <el-checkbox
            v-for="val in examArrangementRuleParam.examArrangementSubjectContinuousParamList"
            :key="val.subjectType"
            v-model="val.examContinuous"
            checked
            :disabled="
              (examArrangementRuleParam
                .examArrangementSubjectContinuousParamList.length >= 2 ||
                examContinuousCount >= 2) &&
              val.examContinuous == true
            "
            @change="conditionChangeDisabled"
            >{{ val.subjectType | subjectTypeFilters }}</el-checkbox
          >
        </template>
      </el-form-item>
      <template v-if="examArrangementRuleParam.arrangeModel == 1">
        <el-form-item
          v-if="!coiled"
          style="margin: -10px 0 0 0"
          v-show="
            examArrangementRuleParam.examArrangementSubjectContinuousParamList
              .length >= 2
          "
          label="考试排序："
        >
          <template>
            <div>
              <div
                class="itemSubject"
                v-for="(
                  val, index
                ) in examArrangementRuleParam.examArrangementSubjectContinuousParamList"
                :key="index"
              >
                <span>{{ index + 1 }}.</span>
              </div>
            </div>
            <div style="position: absolute; top: 0">
              <draggable
                v-model="
                  examArrangementRuleParam.examArrangementSubjectContinuousParamList
                "
                group="people"
                @change="change"
                @start="start"
                @end="end"
              >
                <div
                  class="itemSubject"
                  style="padding-left: 24px; border: 1px solid #cccccc00"
                  v-for="(
                    val, index
                  ) in examArrangementRuleParam.examArrangementSubjectContinuousParamList"
                  :key="index"
                >
                  <!-- <span>{{ index + 1 }}.</span> -->
                  {{ val.subjectType | subjectTypeFilters }}
                </div>
              </draggable>
            </div>
          </template>
        </el-form-item>
        <el-row style="display: flex">
          <el-form-item label="答题时长：" style="display: inline-block">
            <el-input
              :disabled="makeup"
              class="inpTime"
              v-model.number="examArrangementRuleParam.examAnswerTime"
              size="mini"
              oninput="value=(value>1440)?1440:value"
            ></el-input
            >分钟，
          </el-form-item>
          <el-form-item label="考试间隔：">
            <el-input
              :disabled="makeup"
              class="inpTime"
              v-model.number="examArrangementRuleParam.examInterval"
              size="mini"
              oninput="value=(value>1440)?1440:value"
            ></el-input
            >分钟，
          </el-form-item>
        </el-row>
        <!-- <el-row style="display:flex;"> -->
        <el-form-item label="考试开始：">
          <el-input
            :disabled="makeup"
            class="inpTime"
            v-model.number="examArrangementRuleParam.aheadDrawTime"
            size="mini"
            oninput="value=(value>1440)?1440:value"
          ></el-input
          >分钟前，可提前抽签
        </el-form-item>
        <el-form-item label="考试开始：">
          <el-input
            :disabled="makeup"
            class="inpTime"
            v-model.number="examArrangementRuleParam.aheadMinute"
            size="mini"
            oninput="value=(value>1440)?1440:value"
          ></el-input
          >分钟后，可提前交卷
        </el-form-item>
        <el-form-item label="抽签模式：">
          <el-radio-group
            :disabled="makeup"
            v-model="examArrangementRuleParam.drawModel"
          >
            <el-radio :disabled="coiled" :label="1"
              >分开模式
              <el-popover
                placement="top"
                content="按学科、考场分开抽签，使用不同的座位号进行考试。"
                trigger="hover"
              >
                <el-button
                  style="color: #606266"
                  class="el-icon-warning-outline"
                  type="text"
                  slot="reference"
                ></el-button></el-popover
            ></el-radio>
            <el-radio :label="2"
              >集中模式<el-popover
                placement="top"
                content="只需进行一次抽签并使用同样的座位号完成所有考试。"
                trigger="hover"
              >
                <el-button
                  style="color: #606266"
                  class="el-icon-warning-outline"
                  type="text"
                  slot="reference"
                ></el-button> </el-popover
            ></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="排考算法：">
          <el-radio-group
            :disabled="makeup"
            v-model="examArrangementRuleParam.arrangeAlgorithm"
          >
            <el-radio :label="1"
              >并排排考
              <el-popover placement="top" width="400" trigger="hover">
                <div>
                  多学科考试时，可选择在同一时间段内进行多科考试。
                  <table
                    style="border-collapse: collapse"
                    cellspacing="0"
                    cellpadding="0"
                    class="table"
                  >
                    <tbody>
                      <tr>
                        <td id="lineTd">
                          <span style="float: left">考场</span>
                          <span style="float: right">时间</span>
                        </td>
                        <td>08:00～08:15</td>
                        <td>08:15～08:20</td>
                        <td>08:20～08:25</td>
                      </tr>
                      <tr>
                        <td>物理</td>
                        <td><div class="examineeBox1">考生一</div></td>
                        <td><div class="examineeBox2">考生二</div></td>
                        <td><div class="examineeBox3">考生三</div></td>
                      </tr>
                      <tr>
                        <td>生物</td>
                        <td><div class="examineeBox3">考生三</div></td>
                        <td><div class="examineeBox1">考生一</div></td>
                        <td><div class="examineeBox2">考生二</div></td>
                      </tr>
                      <tr>
                        <td>化学</td>
                        <td><div class="examineeBox2">考生二</div></td>
                        <td><div class="examineeBox3">考生三</div></td>
                        <td><div class="examineeBox1">考生一</div></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <el-button
                  style="color: #606266"
                  class="el-icon-warning-outline"
                  type="text"
                  slot="reference"
                ></el-button>
              </el-popover>
            </el-radio>
            <el-radio :label="2"
              >阶梯排考
              <el-popover placement="top" width="400" trigger="hover">
                多学科考试时，必须按顺序完成上一学科考试才能进行下一学科考试。
                <table
                  style="border-collapse: collapse"
                  cellspacing="0"
                  cellpadding="0"
                  class="table"
                >
                  <tbody>
                    <tr>
                      <td id="lineTd">
                        <span style="float: left">考场</span>
                        <span style="float: right">时间</span>
                      </td>
                      <td>08:00～08:15</td>
                      <td>08:15～08:20</td>
                      <td>08:20～08:25</td>
                    </tr>
                    <tr>
                      <td>物理</td>
                      <td><div class="examineeBox1">考生一</div></td>
                      <td><div class="examineeBox2">考生二</div></td>
                      <td><div class="examineeBox3">考生三</div></td>
                    </tr>
                    <tr>
                      <td>生物</td>
                      <td></td>
                      <td><div class="examineeBox1">考生一</div></td>
                      <td><div class="examineeBox2">考生二</div></td>
                    </tr>
                    <tr>
                      <td>化学</td>
                      <td></td>
                      <td></td>
                      <td><div class="examineeBox1">考生一</div></td>
                    </tr>
                  </tbody>
                </table>
                <el-button
                  style="color: #606266"
                  class="el-icon-warning-outline"
                  type="text"
                  slot="reference"
                ></el-button>
              </el-popover>
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- </el-row> -->
        <div style="font-size: 14px">
          <el-row
            >选择每天考试时间：
            <el-popover placement="top" width="400" trigger="hover">
              每天考试时间安排示例：
              <div style="width: 100%">
                <img style="width: 100%" src="@/assets/timePng.png" />
              </div>
              <div style="color: #f59a23">
                注：补考只能使用每天考试时间的空闲间隔进行时间设置，不允许选择到正常考试时间，除结束考试外。
              </div>
              <el-button
                style="color: #606266"
                class="el-icon-warning-outline"
                type="text"
                slot="reference"
              ></el-button></el-popover
          ></el-row>
          <el-form-item>
            <!-- 考试时间卡片 -->
            <el-card>
              <!-- <div slot="header" class="clearfix"> -->
              <!-- <el-checkbox>全选</el-checkbox> -->
              <!-- </div> -->
              <div v-for="(item, i) in timeDataList" :key="i">
                <el-row class="timePosition">
                  <el-time-picker
                    is-range
                    :editable="false"
                    :clearable="false"
                    v-model="item.timeData.value"
                    range-separator="⇌"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    placeholder="选择时间范围"
                    value-format="timestamp"
                    format="HH:mm"
                    @change="timeListBtn(i)"
                  ></el-time-picker>
                  <i v-if="i" class="el-icon-error" @click="deleteTime(i)"></i>
                </el-row>
                <!-- 时间选择 -->
                <div class="timeList">
                  <span
                    class="timeListSpan"
                    v-for="val in item.timeData.listData"
                    :key="val"
                    >{{ val | dayList }}</span
                  >
                </div>
              </div>
              <el-button
                v-throttle
                type="primary"
                @click="addTimeData"
                size="medium"
                >添加时间段</el-button
              >
            </el-card>
          </el-form-item>
        </div>
      </template>
      <template v-else-if="examArrangementRuleParam.arrangeModel == 2">
        <el-form-item label="设置定时：">
          <el-radio-group
            :disabled="makeup"
            v-model="examArrangementRuleParam.fixTime"
          >
            <el-radio :label="false">否</el-radio>
            <el-radio :label="true">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <template v-if="examArrangementRuleParam.fixTime">
          <el-form-item label="定时时长:">
            <el-input
              :disabled="makeup"
              class="inpTime"
              v-model.number="examArrangementRuleParam.fixTimeLength"
              size="mini"
              oninput="value=(value>1440)?1440:value"
            ></el-input
            >分钟，可视情况提前结束考试/继续考试
          </el-form-item>
          <el-form-item label="定时时长:">
            <el-input
              :disabled="makeup"
              class="inpTime"
              v-model.number="examArrangementRuleParam.fixTimeRemindLength"
              size="mini"
              oninput="value=(value>1440)?1440:value"
            ></el-input
            >秒后，可选择继续考试/自动结束考试
          </el-form-item>
        </template>
        <el-form-item label="考试开始：">
          <el-input
            :disabled="makeup"
            class="inpTime"
            v-model.number="examArrangementRuleParam.aheadMinute"
            size="mini"
            oninput="value=(value>1440)?1440:value"
          ></el-input
          >分钟后，可提前交卷
        </el-form-item>
        <!--  label-width="146px" -->
        <el-form-item label="报到模式：">
          <el-radio-group
            :disabled="makeup"
            v-model="examArrangementRuleParam.randomTimingType"
          >
            <el-radio :label="1" :disabled="coiled">分开点名</el-radio>
            <el-radio :label="2">集中点名</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="抽签方式：">
          <el-radio-group
            :disabled="makeup"
            v-model="examArrangementRuleParam.randomTimingDrawType"
          >
            <el-radio :label="1">报到即抽签</el-radio>
            <el-radio :label="2">报到后抽签</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="抽签模式：">
          <el-radio-group
            :disabled="makeup"
            v-model="examArrangementRuleParam.drawModel"
          >
            <el-radio :disabled="coiled" :label="1"
              >分开模式
              <el-popover
                placement="top"
                content="按学科、考场分开抽签，使用不同的座位号进行考试。"
                trigger="hover"
              >
                <el-button
                  style="color: #606266"
                  class="el-icon-warning-outline"
                  type="text"
                  slot="reference"
                ></el-button>
              </el-popover>
            </el-radio>
            <el-radio
              :label="2"
              :disabled="examArrangementRuleParam.randomTimingType == 1"
              >集中模式
              <el-popover
                placement="top"
                content="只需进行一次抽签并使用同样的座位号完成所有考试。"
                trigger="hover"
              >
                <el-button
                  style="color: #606266"
                  class="el-icon-warning-outline"
                  type="text"
                  slot="reference"
                ></el-button>
              </el-popover>
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </template>
      <!-- 定时不定点 -->
      <template v-else>
        <el-row style="display: flex">
          <el-form-item label="答题时长：" style="display: inline-block">
            <el-input
              :disabled="makeup"
              class="inpTime"
              v-model.number="examArrangementRuleParam.examAnswerTime"
              size="mini"
              oninput="value=(value>1440)?1440:value"
            ></el-input
            >分钟，
          </el-form-item>
          <el-form-item label="考试间隔：">
            <el-input
              :disabled="makeup"
              class="inpTime"
              v-model.number="examArrangementRuleParam.examInterval"
              size="mini"
              oninput="value=(value>1440)?1440:value"
            ></el-input
            >分钟，
          </el-form-item>
        </el-row>
        <el-form-item label="设置定时：">
          <el-radio-group
            :disabled="makeup"
            v-model="examArrangementRuleParam.fixTime"
          >
            <el-radio :label="false">否</el-radio>
            <el-radio :label="true">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <template v-if="examArrangementRuleParam.fixTime">
          <el-form-item label="定时时长:">
            <el-input
              disabled
              class="inpTime"
              v-model.number="examArrangementRuleParam.fixTimeLength"
              size="mini"
              oninput="value=(value>1440)?1440:value"
            ></el-input
            >分钟，可视情况提前结束考试/继续考试
          </el-form-item>
          <el-form-item label="定时时长:">
            <el-input
              :disabled="makeup"
              class="inpTime"
              v-model.number="examArrangementRuleParam.fixTimeRemindLength"
              size="mini"
              oninput="value=(value>1440)?1440:value"
            ></el-input
            >秒后，可选择继续考试/自动结束考试
          </el-form-item>
        </template>
        <el-form-item label="考试开始：">
          <el-input
            :disabled="makeup"
            class="inpTime"
            v-model.number="examArrangementRuleParam.aheadMinute"
            size="mini"
            oninput="value=(value>1440)?1440:value"
          ></el-input
          >分钟后，可提前交卷
        </el-form-item>
        <el-form-item label="抽签模式：">
          <el-radio-group
            :disabled="makeup"
            v-model="examArrangementRuleParam.drawModel"
          >
            <el-radio :disabled="coiled" :label="1"
              >分开模式
              <el-popover
                placement="top"
                content="按学科、考场分开抽签，使用不同的座位号进行考试。"
                trigger="hover"
              >
                <el-button
                  style="color: #606266"
                  class="el-icon-warning-outline"
                  type="text"
                  slot="reference"
                ></el-button>
              </el-popover>
            </el-radio>
            <el-radio :label="2"
              >集中模式
              <el-popover
                placement="top"
                content="只需进行一次抽签并使用同样的座位号完成所有考试。"
                trigger="hover"
              >
                <el-button
                  style="color: #606266"
                  class="el-icon-warning-outline"
                  type="text"
                  slot="reference"
                ></el-button>
              </el-popover>
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="排考算法：">
          <el-radio-group
            :disabled="makeup"
            v-model="examArrangementRuleParam.arrangeAlgorithm"
          >
            <el-radio :label="1">并排排考</el-radio>
            <el-radio :label="2">阶梯排考</el-radio>
          </el-radio-group>
        </el-form-item>
        <div style="font-size: 14px">
          <el-row>选择每天考试时间：</el-row>
          <el-form-item>
            <!-- 考试时间卡片 -->
            <el-card>
              <!-- <div slot="header" class="clearfix"> -->
              <!-- <el-checkbox>全选</el-checkbox> -->
              <!-- </div> -->
              <div v-for="(item, i) in timeDataList" :key="i">
                <el-row class="timePosition">
                  <el-time-picker
                    is-range
                    :editable="false"
                    :clearable="false"
                    v-model="item.timeData.value"
                    range-separator="⇌"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    placeholder="选择时间范围"
                    value-format="timestamp"
                    format="HH:mm"
                    @change="timeListBtn(i)"
                  ></el-time-picker>
                  <i v-if="i" class="el-icon-error" @click="deleteTime(i)"></i>
                </el-row>
                <!-- 时间选择 -->
                <div class="timeList">
                  <span
                    class="timeListSpan"
                    v-for="val in item.timeData.listData"
                    :key="val"
                    >{{ val | dayList }}</span
                  >
                </div>
              </div>
              <el-button
                v-throttle
                type="primary"
                @click="addTimeData"
                size="medium"
                >添加时间段</el-button
              >
            </el-card>
          </el-form-item>
        </div>
      </template>
      <el-row class="middleBox">
        <!-- :disabled="makeup" -->
        <el-button
          v-throttle
          type="primary"
          @click="saveBtn"
          size="medium"
          :loading="saveBtnLoading"
          >保存</el-button
        >
        <router-link to="/exam/examManage" replace style="margin-left: 30px">
          <el-button v-throttle size="medium">返回</el-button>
        </router-link>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import dayjs from 'dayjs'
import { isEmpty } from '@/utils/util.js'
import {
  addRule,
  inquireRule,
  inquireRule2,
  renewRule,
  makeupRule,
} from '@/api/exam/exam.js'
// 考试规则
export default {
  name: 'examPlanning',
  props: ['examId', 'firstTime'],
  components: {
    draggable,
  },
  data() {
    return {
      examContinuousCount: 0,
      // 连续考试
      coiled: false,
      // 补考
      makeup: false,
      saveBtnLoading: false,
      // 考试基本规则安排信息
      examArrangementRuleParam: {
        // 考试开始前的抽签时间
        aheadDrawTime: 15,
        // 提前交卷时间
        aheadMinute: 5,
        // 排考模式（1定时定点 2随到随考）
        arrangeModel: 1,
        // 答题时间
        examAnswerTime: 15,
        // 考试时间段集合
        examArrangementTimeSegmentParamList: [
          // {
          //   // 考试时间段结束时间
          //   examEndTimeDiff: 0,
          //   // 考试时间段开始时间
          //   examStartTimeDiff: 0
          // }
        ],
        // 考试间隔
        examInterval: 10,
        // 	随到水考录入模式 （1.分开点名，2集中点名）
        randomTimingType: 1,
        examArrangementSubjectContinuousParamList: [
          {
            examContinuous: false,
            subjectType: 1,
            sortNum: 1,
          },
          {
            examContinuous: false,
            subjectType: 2,
            sortNum: 2,
          },
          {
            examContinuous: false,
            subjectType: 3,
            sortNum: 3,
          },
        ],
        // 排考算法（1并排排考 2阶梯排考 3随到随考排考）
        arrangeAlgorithm: 1,
        // 抽签模式（1分开模式 2集中模式）
        drawModel: 1,
        // 	定时设置
        fixTime: false,
        // 定时时长（单位分）
        fixTimeLength: 15,
        // 定时提醒（单位秒）
        fixTimeRemindLength: 10,
        // 	抽签方式（1报到即抽签 2报到后抽签）
        randomTimingDrawType: 1,
      },
      // 时间段
      timeDataList: [
        // {
        //   timeData: {
        //     value: '',
        //     listData: []
        //   }
        // },
      ],
    }
  },
  methods: {
    // 开始时排序
    compareSortNum(name) {
      return (a, b) => {
        let value1 = a[name]
        let value2 = b[name]
        return value1 - value2
      }
    },
    // 结束时排序
    compareSortNumEnd(array, name) {
      array.forEach((element, index) => {
        element[name] = index + 1
      })
    },
    // 监听拖拽
    change(event) {
      console.log('change')
      console.log(event)
      // console.log(this.examArrangementRuleParam.examArrangementSubjectContinuousParamList);
    },
    // 开始拖拽
    start(event) {
      console.log('start')
      console.log(event)
      // console.log(this.examArrangementRuleParam.examArrangementSubjectContinuousParamList);
    },
    // 结束拖拽
    end(event) {
      console.log('end')
      // // event.item  拖拽的本身
      // // event.to      拖拽的目标列表
      // // event.from    拖拽之前的列表
      // // event.oldIndex    拖拽前的位置
      // // event.newIndex    拖拽后的位置
      console.log(event)
      // console.log(this.examArrangementRuleParam.examArrangementSubjectContinuousParamList);
    },
    // 修改答题时间和考试间隔时间后 修改每天考试显示的时间段
    changTimeList() {
      const interval =
        (this.examArrangementRuleParam.examInterval +
          this.examArrangementRuleParam.examAnswerTime) *
        60000
      this.timeDataList.forEach((val) => {
        let number =
          (val.timeData.value[1] -
            val.timeData.value[0] +
            this.examArrangementRuleParam.examInterval * 60000) /
          interval
        val.timeData.listData = []
        let startNum = val.timeData.value[0]
        for (let index = 1; index <= number; index++) {
          val.timeData.listData.push(startNum)
          startNum += interval
        }
        if (val.timeData.listData.length === 0) {
          this.$message.info('请检查考试时间')
        }
      })
    },
    // 时间段
    timeListBtn(i) {
      if (
        isEmpty(this.examArrangementRuleParam.examAnswerTime) ||
        isEmpty(this.examArrangementRuleParam.examInterval)
      ) {
        this.$message.warning('请先填写答题时长及考试间隔')

        // 清空
        this.timeDataList = [
          {
            timeData: {
              value: [],
              listData: [],
            },
          },
        ]
        return
      }
      if (
        isEmpty(this.timeDataList[i].timeData.value[0]) ||
        isEmpty(this.timeDataList[i].timeData.value[1])
      ) {
        return
      }
      if (
        this.timeDataList[i].timeData.value[0] ==
        this.timeDataList[i].timeData.value[1]
      ) {
        this.$message.warning('时间间隔不能为0')
        this.timeDataList.splice(i, 1)
        return
      }

      const newZeroTime1 = new Date(
        new Date(this.timeDataList[i].timeData.value[0]).toLocaleDateString()
      ).getTime()
      const newZeroTime2 = new Date(
        new Date(this.timeDataList[i].timeData.value[1]).toLocaleDateString()
      ).getTime()

      console.log(this.timeDataList[i].timeData.value[0])
      console.log(this.timeDataList[i].timeData.value[1])

      this.examArrangementRuleParam.examArrangementTimeSegmentParamList[
        i
      ].examStartTimeDiff =
        this.timeDataList[i].timeData.value[0] - newZeroTime1
      this.examArrangementRuleParam.examArrangementTimeSegmentParamList[
        i
      ].examEndTimeDiff = this.timeDataList[i].timeData.value[1] - newZeroTime2

      const interval =
        (this.examArrangementRuleParam.examInterval +
          this.examArrangementRuleParam.examAnswerTime) *
        60000
      //次数 (结束时间 - 开始时间 + 上一段休息时间) / (开始时间 + 休息时间)
      let number =
        (this.timeDataList[i].timeData.value[1] -
          this.timeDataList[i].timeData.value[0] +
          this.examArrangementRuleParam.examInterval * 60000) /
        interval
      number = parseInt(number)
      this.timeDataList[i].timeData.listData = []
      let startNum = this.timeDataList[i].timeData.value[0]
      for (let index = 1; index <= number; index++) {
        this.timeDataList[i].timeData.listData.push(startNum)
        startNum += interval
      }
      if (this.timeDataList[i].timeData.listData.length === 0) {
        this.$message.info('请检查考试时间')
      }
      console.log(
        this.examArrangementRuleParam.examArrangementTimeSegmentParamList[i]
          .examStartTimeDiff
      )
      console.log(
        this.examArrangementRuleParam.examArrangementTimeSegmentParamList[i]
          .examEndTimeDiff
      )
    },
    // 添加时间段
    addTimeData() {
      let date1 = new Date()
      console.log('date1', date1)
      date1.setDate(date1.getDate())
      date1.setHours(0, 0, 0, 0)
      let date2 = new Date()
      console.log('date2', date2)
      date2.setDate(date2.getDate())
      date2.setHours(23, 59, 0, 0)

      // 默认零秒
      let li = {
        timeData: {
          value: [Date.parse(date1), Date.parse(date2)],
          listData: [],
        },
      }
      let list = {
        examEndTimeDiff: 0,
        examStartTimeDiff: 0,
      }
      console.log(list)
      this.timeDataList.push(li)
      this.examArrangementRuleParam.examArrangementTimeSegmentParamList.push(
        list
      )
      console.log(
        '添加',
        this.examArrangementRuleParam.examArrangementTimeSegmentParamList
      )

      this.$nextTick(() => {
        this.timeListBtn(this.timeDataList.length - 1)
      })
    },
    // 删除时间段
    deleteTime(i) {
      this.timeDataList.splice(i, 1)
      this.examArrangementRuleParam.examArrangementTimeSegmentParamList.splice(
        i,
        1
      )
      console.log(
        this.examArrangementRuleParam.examArrangementTimeSegmentParamList
      )
    },
    saveBtn() {
      if (this.judgeData()) return
      this.saveBtnLoading = true
      this.compareSortNumEnd(
        this.examArrangementRuleParam.examArrangementSubjectContinuousParamList,
        'sortNum'
      )
      console.log(this.examArrangementRuleParam)
      if (this.$route.query.mainExamId) {
        // 补考添加
        this.makeupRuleFunc()
      } else {
        if (this.firstTime) {
          console.log('执行更新')
          this.renewAsk()
        } else {
          console.log('执行添加')
          this.addAsk()
        }
      }
    },
    // 添加
    addAsk() {
      console.log(this.examArrangementRuleParam)
      addRule(this.examId, this.examArrangementRuleParam)
        .then((res) => {
          console.log('res', res)
          this.saveBtnLoading = false
          if (res.success) {
            this.parentFunc()
          } else {
            this.$message.info(res.msg)
          }
        })
        .catch((err) => {
          this.saveBtnLoading = false
          this.$message.error('保存失败')
        })
    },
    // 回显查询
    async inquireAsk() {
      let examId
      let first = false
      if (this.$route.query.mainExamId) {
        if (this.firstTime) {
          examId = this.$route.query.examId
        } else {
          first = true
          examId = this.$route.query.mainExamId
        }
      } else {
        examId = this.$route.query.examId
      }
      await inquireRule(examId).then(async (res) => {
        console.log('回显查询', res)
        if (!res.data.arrangeModel) {
          await this.inquireAskRetake()
          return
        }
        // 处理这次点击是第一次添加还是更新判断
        if (res.success) {
          let { data } = res
          this.examArrangementRuleParam.aheadMinute = data.aheadMinute
          this.examArrangementRuleParam.aheadDrawTime = data.aheadDrawTime
          this.examArrangementRuleParam.arrangeModel = data.arrangeModel
          this.examArrangementRuleParam.examAnswerTime = data.examAnswerTime
          this.examArrangementRuleParam.examInterval = data.examInterval

          this.examArrangementRuleParam.arrangeAlgorithm = data.arrangeAlgorithm
          this.examArrangementRuleParam.drawModel = data.drawModel
          this.examArrangementRuleParam.fixTime = data.fixTime
          if (data.arrangeModel != 1) {
            this.examArrangementRuleParam.fixTimeLength = data.fixTimeLength
          }
          this.examArrangementRuleParam.fixTimeRemindLength =
            data.fixTimeRemindLength
          this.examArrangementRuleParam.randomTimingDrawType =
            data.randomTimingDrawType

          this.examArrangementRuleParam.examArrangementSubjectContinuousParamList =
            data.examArrangementSubjectContinuousList
          this.examArrangementRuleParam.examArrangementSubjectContinuousParamList.sort(
            this.compareSortNum('sortNum')
          )
          this.examArrangementRuleParam.randomTimingType = data.randomTimingType
          // 处理时间
          this.examArrangementRuleParam.examArrangementTimeSegmentParamList = []
          this.timeDataList = []
          console.log('data', data)
          if (first) {
            data.examArrangementTimeSegmentList = []
          }
          data.examArrangementTimeSegmentList.sort((a, b) => {
            return a.examStartTimeDiff - b.examStartTimeDiff
          })
          // data.examArrangementTimeDTOList.forEach((val, i) => {
          const zeroTime = new Date(new Date().toLocaleDateString()).getTime()
          data.examArrangementTimeSegmentList.forEach((val, i) => {
            this.addTimeData()
            this.timeDataList[i].timeData.value = []
            this.timeDataList[i].timeData.value[0] =
              val.examStartTimeDiff + zeroTime
            this.timeDataList[i].timeData.value[1] =
              val.examEndTimeDiff + zeroTime
            console.log('中间值', i, this.timeDataList)
            this.timeListBtn(i)
          })
          console.log('timeDataList', this.timeDataList)
          // timeListBtn (i)
          this.subjectContinuousListener()
        }
      })
    },
    // 补考回显查询
    async inquireAskRetake() {
      let examId
      let first = false
      if (this.$route.query.mainExamId) {
        if (this.firstTime) {
          examId = this.$route.query.examId
        } else {
          first = true
          examId = this.$route.query.mainExamId
        }
      } else {
        examId = this.$route.query.examId
      }
      await inquireRule2(examId).then((res) => {
        console.log('回显查询', res)
        // 处理这次点击是第一次添加还是更新判断
        if (res.success) {
          let { data } = res
          this.examArrangementRuleParam.aheadMinute = data.aheadMinute
          this.examArrangementRuleParam.aheadDrawTime = data.aheadDrawTime
          this.examArrangementRuleParam.arrangeModel = data.arrangeModel
          this.examArrangementRuleParam.examAnswerTime = data.examAnswerTime
          this.examArrangementRuleParam.examInterval = data.examInterval

          this.examArrangementRuleParam.arrangeAlgorithm = data.arrangeAlgorithm
          this.examArrangementRuleParam.drawModel = data.drawModel
          this.examArrangementRuleParam.fixTime = data.fixTime
          if (data.arrangeModel != 1) {
            this.examArrangementRuleParam.fixTimeLength = data.fixTimeLength
          }
          this.examArrangementRuleParam.fixTimeRemindLength =
            data.fixTimeRemindLength
          this.examArrangementRuleParam.randomTimingDrawType =
            data.randomTimingDrawType

          this.examArrangementRuleParam.examArrangementSubjectContinuousParamList =
            data.examSubjectContinuousList
          this.examArrangementRuleParam.examArrangementSubjectContinuousParamList.sort(
            this.compareSortNum('sortNum')
          )
          this.examArrangementRuleParam.randomTimingType = data.randomTimingType
          // 处理时间
          this.examArrangementRuleParam.examArrangementTimeSegmentParamList = []
          this.timeDataList = []
          console.log('data', data)
          if (first) {
            data.examArrangementTimeSegmentList = []
          }
          data.examArrangementTimeSegmentList.sort((a, b) => {
            return a.examStartTimeDiff - b.examStartTimeDiff
          })
          // data.examArrangementTimeDTOList.forEach((val, i) => {
          const zeroTime = new Date(new Date().toLocaleDateString()).getTime()
          data.examArrangementTimeSegmentList.forEach((val, i) => {
            this.addTimeData()
            this.timeDataList[i].timeData.value = []
            this.timeDataList[i].timeData.value[0] =
              val.examStartTimeDiff + zeroTime
            this.timeDataList[i].timeData.value[1] =
              val.examEndTimeDiff + zeroTime
            console.log('中间值', i, this.timeDataList)
            this.timeListBtn(i)
          })
          console.log('timeDataList', this.timeDataList)
          // timeListBtn (i)
          this.subjectContinuousListener()
        }
      })
    },
    // 查询回显 只需要联考科目
    inquireAskSubject() {
      inquireRule(this.$route.query.examId).then((res) => {
        console.log('回显查询', res)
        // 处理这次点击是第一次添加还是更新判断
        if (res.success) {
          let { data } = res
          this.examArrangementRuleParam.examArrangementSubjectContinuousParamList =
            data.examArrangementSubjectContinuousList
          this.examArrangementRuleParam.examArrangementSubjectContinuousParamList.sort(
            this.compareSortNum('sortNum')
          )
          this.subjectContinuousListener()
        }
      })
    },
    // 更新
    renewAsk() {
      renewRule(this.examId, this.examArrangementRuleParam)
        .then((res) => {
          console.log('更新', res)
          this.saveBtnLoading = false
          if (res.success) {
            this.parentFunc()
          } else {
            this.$message.info(res.msg)
          }
        })
        .catch((err) => {
          this.saveBtnLoading = false
          this.$message.error('保存失败')
        })
    },
    // 添加或更新考试补考安排时间段
    makeupRuleFunc() {
      let data = {}
      data.examArrangementTimeSegmentParamList =
        this.examArrangementRuleParam.examArrangementTimeSegmentParamList
      data.examArrangementSubjectContinuousParamList =
        this.examArrangementRuleParam.examArrangementSubjectContinuousParamList
      makeupRule(this.examId, data)
        .then((res) => {
          this.saveBtnLoading = false
          if (res.success) {
            this.parentFunc()
          } else {
            this.$message.info(res.msg)
          }
        })
        .catch((err) => {
          this.saveBtnLoading = false
          this.$message.error('保存失败')
        })
    },
    //
    numWatchFunc(newVal, oldVal) {
      if (isNaN(newVal) || newVal <= 0) {
        this.$message.warning('必填项只能设置为正整数')
        return 1
      } else {
        return Math.floor(newVal)
      }
    },
    // 判断数据
    judgeData() {
      // if (this.examArrangementRuleParam.arrangeModel == 1 && this.timeDataList.length != 0) {
      //   this.timeDataList.forEach(val => {
      //     if (val.listDate != undefined && val.listDate != null && val.listDate, length == 0) {
      //       this.$message.warning('时间段不能为空')
      //       return true
      //     }
      //   });
      // }
      if (
        this.examArrangementRuleParam.arrangeModel == 2 &&
        !this.examArrangementRuleParam.randomTimingDrawType
      ) {
        this.$message.warning('请选择抽签方式')
        return true
      }
      if (!this.examId) {
        this.$message.warning('请先将基本信息模块的数据保存')
      } else if (
        this.examArrangementRuleParam.arrangeModel != 2 &&
        this.timeDataList.length == 0
      ) {
        this.$message.warning('请选择每天考试时间')
      } else {
        return false
      }
      return true
    },
    // 触发父组件方法
    parentFunc() {
      this.$emit('parentFunc', 2)
    },
    subjectContinuousListener() {
      if (
        this.examArrangementRuleParam
          .examArrangementSubjectContinuousParamList &&
        this.examArrangementRuleParam.examArrangementSubjectContinuousParamList
          .length >= 1
      ) {
        this.examArrangementRuleParam.examArrangementSubjectContinuousParamList.forEach(
          (val) => {
            if (val.examContinuous) {
              this.coiled = true
            }
          }
        )
      }
    },
    conditionChangeDisabled() {
      this.examContinuousCount = 0
      this.examArrangementRuleParam.examArrangementSubjectContinuousParamList.forEach(
        (item) => {
          if (item.examContinuous == true) {
            this.examContinuousCount++
          }
        }
      )
    },
    // 切换后给默认值
    arrangeModelHandoffFunc(val) {
      if (!this.examArrangementRuleParam.aheadDrawTime) {
        this.examArrangementRuleParam.aheadDrawTime = 5
      }
      if (!this.examArrangementRuleParam.examAnswerTime) {
        this.examArrangementRuleParam.examAnswerTime = 10
      }
      if (!this.examArrangementRuleParam.examInterval) {
        this.examArrangementRuleParam.examInterval = 5
      }
      if (
        this.examArrangementRuleParam.arrangeAlgorithm == 3 &&
        this.examArrangementRuleParam.arrangeModel != 2
      ) {
        this.examArrangementRuleParam.arrangeAlgorithm = 1
      } else if (this.examArrangementRuleParam.arrangeModel == 2) {
        this.examArrangementRuleParam.arrangeAlgorithm = 3
      }

      if (val === 2) {
        this.coiledFunc(this.coiled)
      }
    },
    // 开启多科联考后 报道模式切换集中点名
    coiledFunc(val) {
      if (this.examArrangementRuleParam.arrangeModel == 2) {
        this.examArrangementRuleParam.randomTimingType = val
          ? 2
          : this.examArrangementRuleParam.randomTimingType
      }
      this.examArrangementRuleParam.drawModel = val
        ? 2
        : this.examArrangementRuleParam.drawModel
    },
  },
  filters: {
    dayList(val) {
      return dayjs(val).format('HH:mm')
    },
    subjectTypeFilters(key) {
      switch (key) {
        case 1:
          return `物理`
        case 2:
          return `生物`
        case 3:
          return `化学`
        default:
          return `subjectType值为${key}`
      }
    },
  },
  async mounted() {
    if (this.$route.query.mainExamId) {
      if (this.$route.query.examId) {
        await this.inquireAsk()
      } else {
        await this.inquireAskRetake()
      }
      this.makeup = true
      console.log(
        'this.makeup',
        this.makeup,
        this.examArrangementRuleParam.arrangeModel
      )
      if (this.makeup && this.examArrangementRuleParam.arrangeModel == 2) {
        this.examArrangementRuleParam.randomTimingType = 1
      }
    } else {
      if (this.firstTime) {
        console.log('执行查询')
        this.inquireAsk()
      } else if (this.$route.query.examId) {
        this.inquireAskSubject()
      }
    }
    this.conditionChangeDisabled()
  },
  watch: {
    'examArrangementRuleParam.arrangeModel'(newVal, oldVal) {
      if (this.examArrangementRuleParam.arrangeModel == 2) {
        !this.examArrangementRuleParam.randomTimingType &&
          (this.examArrangementRuleParam.randomTimingType = 1)
      }
    },
    'examArrangementRuleParam.aheadDrawTime'(newVal, oldVal) {
      if (this.examArrangementRuleParam.arrangeModel != 1) return
      this.examArrangementRuleParam.aheadDrawTime = this.numWatchFunc(
        newVal,
        oldVal
      )
    },
    'examArrangementRuleParam.aheadMinute'(newVal, oldVal) {
      this.examArrangementRuleParam.aheadMinute = this.numWatchFunc(
        newVal,
        oldVal
      )
    },
    'examArrangementRuleParam.examAnswerTime'(newVal, oldVal) {
      if (this.examArrangementRuleParam.arrangeModel == 2) return
      if (this.timeDataList.length && newVal != oldVal) {
        this.changTimeList()
      }
      this.examArrangementRuleParam.examAnswerTime = this.numWatchFunc(
        newVal,
        oldVal
      )
      // if (
      //   this.examArrangementRuleParam.arrangeModel == 3 &&
      //   this.examArrangementRuleParam.fixTime
      // ) {
      this.examArrangementRuleParam.fixTimeLength = Number(
        this.examArrangementRuleParam.examAnswerTime
      )
      // }
    },
    'examArrangementRuleParam.fixTimeLength'(newVal, oldVal) {
      if (newVal <= 0) {
        this.examArrangementRuleParam.fixTimeLength = 1
      }
      if (
        this.examArrangementRuleParam.fixTimeLength !=
        this.examArrangementRuleParam.examAnswerTime
      ) {
        this.examArrangementRuleParam.examAnswerTime = Number(
          this.examArrangementRuleParam.fixTimeLength
        )
      }
    },
    'examArrangementRuleParam.examInterval'(newVal, oldVal) {
      if (this.examArrangementRuleParam.arrangeModel == 2) return
      if (this.timeDataList.length && newVal != oldVal) {
        this.changTimeList()
      }
      this.examArrangementRuleParam.examInterval = this.numWatchFunc(
        newVal,
        oldVal
      )
    },
    'examArrangementRuleParam.randomTimingType'(val) {
      if (val == 1 && this.examArrangementRuleParam.arrangeModel == 2) {
        this.examArrangementRuleParam.drawModel = 1
      }
    },
    coiled(key) {
      if (key) {
      } else {
        if (
          this.examArrangementRuleParam
            .examArrangementSubjectContinuousParamList &&
          this.examArrangementRuleParam
            .examArrangementSubjectContinuousParamList.length >= 2
        ) {
          this.examArrangementRuleParam.examArrangementSubjectContinuousParamList.forEach(
            (val) => {
              val.examContinuous = false
              // console.log(val);
            }
          )
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__label {
  color: #000;
}

::v-deep .inpTime {
  width: 50px;
  margin-right: 5px;

  input {
    padding: 0;
    text-align: center;
  }
}

::v-deep .el-card {
  width: 410px;
  margin-top: 10px;
}

::v-deep .timeList {
  margin-bottom: 10px;

  .timeListSpan {
    display: inline-block;
    padding: 5px;
    margin-top: 10px;
    // border: 1px solid #ccc;
    border-radius: 5px;
    line-height: 24px;
  }
}

::v-deep .timePosition {
  position: relative;
}

::v-deep .el-icon-error {
  color: red;
  cursor: pointer;
  position: absolute;

  width: 16px;
  height: 16px;
  font-size: 16px;
  top: calc(50% - 8px);
  right: -6px;
}

::v-deep .itemSubject {
  border: 1px solid #ccc;
  margin: 10px 0;
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
  box-sizing: border-box;
  cursor: pointer;
  width: 100px;
  border-radius: 8px;
}

#lineTd {
  background: #fff
    url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiPjxsaW5lIHgxPSIwIiB5MT0iMCIgeDI9IjEwMCUiIHkyPSIxMDAlIiBzdHJva2U9IiNjY2MiIHN0cm9rZS13aWR0aD0iMSIvPjwvc3ZnPgo=)
    no-repeat 100% center;
  font-weight: bold;
}
.table {
  border: 1px solid #fff;
  /*去掉表格之间的空隙*/
  border-collapse: collapse;
  margin: 20px auto;

  caption {
    font-size: 20px;
    font-weight: bold;
  }

  th,
  td {
    border: 1px solid #ccc;
    width: 100px;
    font-size: 14px;
    line-height: 40px;
    text-align: center;
  }
  .examineeBox1 {
    color: #fff;
    background: #1886fe;
    border-radius: 5px;
    width: 60px;
    height: 32px;
    margin: 0 auto;
    line-height: 32px;
    font-size: 14px;
  }
  .examineeBox2 {
    color: #fff;
    background: #01a850;
    border-radius: 5px;
    width: 60px;
    height: 32px;
    margin: 0 auto;
    line-height: 32px;
    font-size: 14px;
  }
  .examineeBox3 {
    color: #fff;
    background: #f59a23;
    border-radius: 5px;
    width: 60px;
    height: 32px;
    margin: 0 auto;
    line-height: 32px;
    font-size: 14px;
  }
}
</style>

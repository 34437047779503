<template>
  <div class="examAddBox">
    <!-- 导航栏 -->
    <div class="examAddTop">
      <!-- :class="isActive == i ? 'addclass' : '' " -->
      <div
        v-for="(item,i) in comList"
        :key="item.com"
        :class="(isActive == i) ? (item.isCorrect ? ' active' :' activeErr') 
        : (item.isSave ? (item.isCorrect ? ' defaultCorrect' :' defaultErr') :'')"
        @click="changeCom($event,i)"
        class="examAddTopList"
      >
        <span>
          <template v-if="item.isSave">
            <i class="el-icon-check" v-if="item.isCorrect"></i>
            <template v-else>!</template>
          </template>
          <template v-else>{{i + 1}}</template>
        </span>
        {{item.name}}
      </div>
    </div>
    <!-- 组件 -->
    <!-- <keep-alive> -->
    <component
      @parentFunc="parentFunc"
      @detectFun="detectFun"
      @arrangeStateChange="arrangeStateChange"
      :is="showCom"
      :examId="examId"
      :firstTime="comList[isActive].isSave"
    ></component>
    <!-- </keep-alive> -->
  </div>
</template>
  
<script>
import { isEmpty } from "@/utils/util.js";
import { detectExam } from "@/api/exam/exam.js";
import { basicList } from "@/api/exam/resultPreview.js";
import basicInformation from "./components/basicInformation.vue";
import examPlanning from "./components/examPlanning.vue";
import locationArrangement from "./components/locationArrangement.vue";
import monitoringArrangements from "./components/monitoringArrangements.vue";
import candidateArrangement from "./components/candidateArrangement.vue";
import resultPreview from "./components/resultPreview.vue";
export default {
  // 添加考试
  name: "examAdd",
  components: {
    basicInformation,
    candidateArrangement,
    examPlanning,
    locationArrangement,
    monitoringArrangements,
    resultPreview
  },
  data () {
    return {
      showCom: '',
      // isSave 是否保存; isCorrect 是否参数正确;
      comList: [
        { name: "基本信息", isSave: 0, isCorrect: 1, com: 'basicInformation' },
        { name: "考试规则", isSave: 0, isCorrect: 1, com: 'examPlanning' },
        { name: "考场安排", isSave: 0, isCorrect: 1, com: 'locationArrangement' },
        { name: "监考安排", isSave: 0, isCorrect: 1, com: 'monitoringArrangements' },
        { name: "考生安排", isSave: 0, isCorrect: 1, com: 'candidateArrangement' },
        { name: "结果预览", isSave: 0, isCorrect: 1, com: 'resultPreview' },
      ],
      // 光标
      isActive: 0,
      examId: '',
      allList: false,
      arrangeState: false,
    }
  },
  methods: {
    changeCom (val, i) {
      // if (isEmpty(this.$route.query.examId)) {
      //   this.$message.warning('请先将基本信息模块的数据保存');
      //   return
      // }
      if (i == 5 && this.allList) {
        this.isActive = i
        this.showCom = this.comList[i].com
      } else if (i == 5) {
        this.$message.warning('请先将前5项数据保存，才能进入结果预览');
      }
      else if (i != 5 && this.allList && this.showCom == "resultPreview" && this.arrangeState) {
        this.$message.warning('如需回到修改页面请取消排考');
      }
      else {
        this.isActive = i
        this.showCom = this.comList[i].com
      }
    },
    getUrlExamId () {
      if (isEmpty(this.$route.query.examId)) {
        this.showCom = "basicInformation"
        this.isActive = 0
        this.allList = false
        return
      }
      this.examId = this.$route.query.examId
      this.detectFun()
    },
    // 检查是否保存
    detectFun () {
      console.log('this.examId', this.examId);
      if (isEmpty(this.examId)) return
      detectExam(this.examId).then((res) => {
        console.log('保存的数据', res)
        if (res.success) {
          res.data.forEach(val => {
            this.comList[val.checkStep - 1].isSave = 1;
            if (val.checkStatus == 1) {
              this.comList[val.checkStep - 1].isCorrect = 0
            } else if (val.checkStatus) {
              this.comList[val.checkStep - 1].isCorrect = 1
            }
          });
          if (res.data.length >= 5) {
            this.allList = true
            this.draftStatusFunc()
          } else if (this.showCom == '') {
            this.showCom = "basicInformation"
            this.isActive = 0
            this.allList = false
          }
        } else {
          this.showCom = "basicInformation"
          this.isActive = 0
        }
      })
        .catch(err => {
          console.log('err', err);
          this.showCom = "basicInformation"
          this.isActive = 0
        })
    },
    // 判断是否排考
    draftStatusFunc () {

      basicList(this.examId).then(res => {
        console.log('判断是否排考', res);
        if (res.success) {
          if (res.data.draftStatus >= 4) {
            this.arrangeState = true
            this.showCom = "resultPreview"
            this.isActive = 5
          } else {
            // 判断是否为空
            if (!this.showCom) {
              this.showCom = "basicInformation"
              this.isActive = 0
              this.arrangeState = false
            }
          }
        } else {
          // 判断是否为空
          if (!this.showCom) {
            this.showCom = "basicInformation"
            this.isActive = 0
            this.arrangeState = false
          }
        }
      })
    },
    arrangeStateChange (val) {
      this.arrangeState = val
    },
    // 子组件触发
    parentFunc (val) {
      this.detectFun()
      this.isActive = val
      this.showCom = this.comList[val].com
    }

  },
  watch: {
    $route () {
      this.getUrlExamId()
    },
  },
  mounted () {
    this.getUrlExamId()
  }
};
</script>

<style lang="scss" scoped>
.examAddBox {
  padding-top: 40px;
}
.examAddTop {
  z-index: 5;
  background: #f7f9fa;
  position: absolute;
  // top: 35px;
  top: 0;
  width: 100%;
  height: 50px;
  line-height: 50px;
  left: 0;
  box-sizing: border-box;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &::after {
    content: '';
    width: calc(100% - 40px);
    position: absolute;
    left: 20px;
    top: 25px;
    height: 1px;
    background: rgb(66, 66, 66);
    z-index: 2;
  }
  .examAddTopList {
    position: relative;
    z-index: 3;
    cursor: pointer;
    height: 30px;
    margin: 10px 0;
    line-height: 30px;
    box-sizing: border-box;
    padding: 0 5px;
    background: #f7f9fa;
    vertical-align: middle;
    display: flex;
    font-size: 18px;
    align-items: center;
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      border: 1px solid #ccc;
      border-radius: 50%;
      margin-right: 4px;
      font-size: 16px;
      i {
        width: 20px;
        height: 20px;
        font-size: 20px;
      }
    }
    // 普通悬停状态
    &:hover {
      color: #1890ff;
      span {
        border-color: #1890ff;
        background: #f7f9fa;
      }
    }
    // 保存状态
    &.defaultCorrect {
      span {
        border-color: #1890ff;
        color: #1890ff;
      }
    }
    // 错误状态
    &.defaultErr {
      span {
        border-color: #f56c6c;
        background: #f7f9fa;
        color: #f56c6c;
      }
      &:hover {
        color: #f56c6c;
      }
    }
    // 选中状态
    &.active {
      color: #1890ff;
      span {
        border-color: #1890ff;
        background: #1890ff;
        color: #fff;
      }
      &:hover {
        span {
          background: #f7f9fa;
          color: #1890ff;
        }
      }
    }
    // 错误选中状态
    &.activeErr {
      color: #f56c6c;
      span {
        border-color: #f56c6c;
        background: #f56c6c;
        color: #fff;
      }
      &:hover {
        span {
          background: #f7f9fa;
          color: #f56c6c;
        }
      }
    }
  }
}
</style>

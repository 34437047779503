<template>
  <div v-loading="saveBtnLoading" element-loading-text="保存中">
    <div class="titleText">已选考场</div>
    <el-row class="middleBoxSpacing">
      <!-- 全部考场 -->
      <el-card :body-style="{ padding: '0px' }">
        <div class="treeHead" slot="header">全部考场</div>
        <div class="treeBox">
          <!-- 筛选 -->
          <div class="treeBoxTop">
            <el-input
              placeholder="输入关键字进行过滤"
              v-model="filterTextLeft"
            ></el-input>
          </div>
          <!-- 底部信息 -->
          <!-- <div class="treeBoxBottom">共有：2考点，2物理考场，2化学考场</div> -->
          <!-- 树 -->
          <div
            class="treeBoxContent"
            v-loading="treeLeftLoading"
            element-loading-text="加载中"
          >
            <el-tree
              show-checkbox
              class="filter-tree"
              :data="treeLeft"
              :props="defaultPropsLeft"
              default-expand-all
              node-key="orgId"
              :default-checked-keys="checKedList"
              :filter-node-method="filterNode"
              ref="treeLeft"
            ></el-tree>
          </div>
        </div>
      </el-card>
      <!-- 迁移按钮 -->
      <el-button v-throttle type="primary" @click="migrateBtn">
        <div style="display: flex; align-items: center; line-height: 20px">
          添加
          <i
            class="el-icon-arrow-right el-icon--right"
            style="font-size: 20px"
          ></i>
        </div>
      </el-button>
      <!-- 右边 -->
      <el-card :body-style="{ padding: '0px' }">
        <div class="treeHead" slot="header">已选考场</div>
        <div class="treeBox">
          <!-- 筛选 -->
          <div class="treeBoxTop">
            <el-input
              placeholder="输入关键字进行过滤"
              v-model="filterTextRight"
            ></el-input>
          </div>
          <!-- 底部信息 -->
          <!-- <div class="treeBoxBottom">共有：2考点，2物理考场，2化学考场</div> -->
          <!-- 树 -->
          <div class="treeBoxContent">
            <el-tree
              class="filter-tree"
              :data="treeRight"
              :props="defaultPropsRight"
              default-expand-all
              :filter-node-method="filterNode"
              ref="treeRight"
            >
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <span>{{ node.label }}</span>
                <span>
                  <i
                    class="el-icon-remove"
                    @click="() => remove(node, data)"
                  ></i>
                </span>
              </span>
            </el-tree>
          </div>
        </div>
      </el-card>
    </el-row>
    <el-row class="middleBox">
      <el-button
        v-throttle
        type="primary"
        @click="saveBtn"
        size="medium"
        :loading="saveBtnLoading"
        >保存</el-button
      >
      <router-link to="/exam/examManage" replace style="margin-left: 30px">
        <el-button v-throttle size="medium">返回</el-button>
      </router-link>
    </el-row>
  </div>
</template>

<script>
import { rootOr, addPlace, inquirePlace, renewPlace } from '@/api/exam/exam.js'
import { makeupPlace } from '@/api/exam/exam.js'
export default {
  name: 'locationArrangement',
  props: ['examId', 'firstTime'],
  data() {
    return {
      saveBtnLoading: false,
      treeLeftLoading: false,
      // 请求信息
      testInformation: {
        getExamPlace: 1,
        getSchool: 0,
      },
      filterTextLeft: '',
      filterTextRight: '',
      treeLeft: [],
      treeRight: [],
      defaultPropsLeft: {
        children: 'childrenOrg',
        label: 'orgName',
      },
      defaultPropsRight: {
        children: 'childrenOrg',
        label: 'orgName',
      },
      // 提交
      askList: [],
      // 回显
      checKedList: [],
    }
  },
  watch: {
    filterTextLeft(val) {
      this.$refs.treeLeft.filter(val)
    },
    filterTextRight(val) {
      this.$refs.treeRight.filter(val)
    },
  },
  methods: {
    // 筛选 不过滤子
    filterNode(value, data, node) {
      if (!value) return true
      let _array = [] //这里使用数组存储 只是为了存储值。
      this.getReturnNode(node, _array, value)
      let result = false
      _array.forEach((item) => {
        result = result || item
      })
      return result
    },
    getReturnNode(node, _array, value) {
      let isPass =
        node.data &&
        node.data.orgName &&
        node.data.orgName.indexOf(value) !== -1
      isPass ? _array.push(isPass) : ''
      if (!isPass && node.level != 1 && node.parent) {
        this.getReturnNode(node.parent, _array, value)
      }
    },

    // 移除
    remove(node, data) {
      const parent = node.parent
      const childrenOrg = parent.data.childrenOrg || parent.data
      const index = childrenOrg.findIndex((d) => d.orgId === data.orgId)
      childrenOrg.splice(index, 1)
    },
    // 迁移方法
    migrateBtn() {
      const getC = this.$refs.treeLeft.getCheckedNodes()
      const getHC = this.$refs.treeLeft.getHalfCheckedNodes()
      let selected_nodes = getHC.concat(getC)
      let selected_ids = new Set() // 记录选中的节点tree_id
      let used_ids = new Set() // 记录已经查询过的节点
      // 遍历初始化selected_ids
      for (let node of selected_nodes) {
        selected_ids.add(node.$treeNodeId)
      }
      // 最终的结果数据用一个根节点存储, 为了对齐递归的数据结构
      let root_node = {
        treeNodeId: -1,
        childrenOrg: [],
      }
      // 添加给parent_node节点一个新节点 node
      // 注意:node 的类型是原始的 tree 节点
      // 而parent_node 的类型是真正的结果节点
      function AddNode(node, parent_node) {
        if (
          !selected_ids.has(node.$treeNodeId) ||
          used_ids.has(node.$treeNodeId)
        ) {
          return
        }
        used_ids.add(node.$treeNodeId) // 加过的要存入 used_ids 做标记
        let real_node = {
          treeNodeId: node.$treeNodeId,
          childrenOrg: [],
          orgName: node.orgName,
          orgId: node.orgId,
          orgType: node.orgType,
          // parentId: node.parentId,
          desc: node.desc,
          grade: node.grade,
          // 存储一下节点的数据
        }
        // // 添加子节点
        if (node.childrenOrg) {
          for (let child_node of node.childrenOrg) {
            AddNode(child_node, real_node)
          }
        }
        if (real_node.childrenOrg.length === 0) {
          delete real_node.childrenOrg
        }
        if (!real_node.grade) {
          delete real_node.grade
        }
        if (!real_node.desc) {
          delete real_node.desc
        }
        parent_node.childrenOrg.push(real_node)
      }
      for (let node of selected_nodes) {
        AddNode(node, root_node)
      }
      let result = root_node.childrenOrg
      console.log('result', result)
      this.treeRight = result
    },
    // 递归过滤获取考场ID
    filterList(arr) {
      if (arr.length) {
        arr.forEach((val) => this.filterList(val))
      } else {
        if (arr.orgType == 3 && arr.childrenOrg && arr.childrenOrg.length) {
          let li = { examPlaceId: 0, examPlaceRoomIdList: [] }
          li.examPlaceId = arr.orgId
          arr.childrenOrg.forEach((val) => {
            val.orgType == 31 && li.examPlaceRoomIdList.push(val.orgId)
          })
          this.askList.push(li)
        }
        arr.childrenOrg &&
          arr.childrenOrg.length &&
          this.filterList(arr.childrenOrg)
        return
      }
    },
    // 回显
    echoTree(arrData) {
      this.checKedList = []
      arrData.forEach((val) => {
        this.checKedList.push(...val.examPlaceRoomIdList)
      })
    },
    // 保存
    saveBtn() {
      this.askList = []
      this.filterList(this.treeRight)
      // 校验
      if (this.judgeData()) return
      this.saveBtnLoading = true
      if (this.$route.query.mainExamId) {
        this.makeupPlaceFunc()
      } else {
        if (this.firstTime) {
          console.log('执行更新')
          this.renewAsk()
        } else {
          console.log('执行添加')
          this.addAsk()
        }
      }
    },
    // 添加
    addAsk() {
      addPlace(this.examId, this.askList)
        .then((res) => {
          console.log('res添加', res)
          this.saveBtnLoading = false
          if (res.success) {
            this.parentFunc()
          } else {
            this.$message.info(res.msg)
          }
        })
        .catch((err) => {
          this.saveBtnLoading = false
          this.$message.error('保存失败')
        })
    },
    // 查询
    async inquireAsk() {
      await inquirePlace(this.examId).then((res) => {
        console.log('res---', res)
        if (res.success && res.data.length != 0) {
          this.echoTree(res.data)
        } else {
          this.$message.info(res.msg)
        }
      })
    },
    // 更新
    renewAsk() {
      renewPlace(this.examId, this.askList)
        .then((res) => {
          console.log('考场更新', res)
          this.saveBtnLoading = false
          if (res.success) {
            this.parentFunc()
          } else {
            this.$message.info(res.msg)
          }
        })
        .catch((err) => {
          this.saveBtnLoading = false
          this.$message.error('保存失败')
        })
    },
    // 添加或更新考试补考安排考点考场
    makeupPlaceFunc() {
      makeupPlace(this.examId, this.askList)
        .then((res) => {
          console.log('补考考场更新', res)
          this.saveBtnLoading = false
          if (res.success) {
            this.parentFunc()
          } else {
            this.$message.info(res.msg)
          }
        })
        .catch((err) => {
          this.saveBtnLoading = false
          this.$message.error('保存失败')
        })
    },
    // 获取全部考场
    async getAllExaminationRoom() {
      this.treeLeftLoading = true
      await rootOr(this.testInformation).then((res) => {
        console.log('res', res)
        if (res.success) {
          this.treeLeft = []
          this.treeLeft.push(res.data)
          this.treeLeftLoading = false
        } else {
          this.treeLeftLoading = false
        }
      })
    },
    // 判断数据
    judgeData() {
      if (!this.examId) {
        this.$message.warning('请先将基本信息模块的数据保存')
      } else if (!this.askList.length) {
        this.$message.warning('已选考场不能为空')
      } else {
        return false
      }
      return true
    },
    // 触发父组件方法
    parentFunc() {
      this.$emit('parentFunc', 3)
    },
  },
  async mounted() {
    console.log(this.firstTime)
    await this.getAllExaminationRoom()

    if (this.firstTime) {
      console.log('查询')
      await this.inquireAsk()
      this.migrateBtn()
    }
  },
}
</script>

<style lang="scss" scoped>
// 美化滚动条
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

::v-deep .titleText {
  text-align: center;
  margin-top: 40px;
  font-weight: bold;
  font-size: 18px;
}
::v-deep .el-card__header {
  background: #dfe6ec;
}
::v-deep .el-card {
  margin: 40px 0;
  width: 400px;
  height: 500px;

  .treeHead {
    text-align: center;
  }

  .treeBox {
    width: 100%;
    position: relative;
    height: 440px;
    // height: 410px;
    box-sizing: border-box;
    padding: 45px 20px 0px 20px;
    overflow-y: auto;
  }

  .treeBoxTop {
    position: absolute;
    width: 100%;
    height: 45px;
    top: 0;
    left: 0;
    box-sizing: border-box;
    background: #fff;
    padding: 0 10px;

    .el-input {
      padding: 5px 0;
      box-sizing: border-box;
    }
  }

  .treeBoxBottom {
    position: absolute;
    width: 100%;
    height: 30px;
    border-top: 1px #eee solid;
    line-height: 30px;
    text-align: center;
    font-size: 15px;
    bottom: 0;
    left: 0;
    background: #fff;
  }

  .treeBoxContent {
    height: 390px;
    width: 100%;
    overflow-y: auto;
    font-size: 14px;
  }
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.el-icon-remove {
  color: red;
  cursor: pointer;
}
</style>
